import reduxStore from '@/redux/store';
import '@/scss/tailwind.css';
import fetchFooter from '@/src/lib/contentful/fetchFooter';
import getSSRConfig from '@/src/lib/getSSRConfig';
import rewriteRoutes from '@/src/lib/rewriteRoutes';
import type AppLayoutProps from 'next/app';
import dynamic from 'next/dynamic';
import AllPages from '@/src/components/Tagmanager/AllPages';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Workbox } from 'workbox-window';
import { DeviceContextProvider } from '../context/DeviceContext';
import { getUserLocation } from '../redux/actions/LocationPrompt';
import { setContextDevice, setDevice } from '../redux/actions/actions';
import { getCookie, getCookies, setCookie } from '../src/Utils/Cookies';
import { gtmScript, loadCleverTap } from '../src/thirdPartyScripts';
import '@vtmn-play/design-tokens/foundations';
import { Roboto_Condensed as robotoCondensed } from 'next/font/google';
import Head from 'next/head';
import { addItemToCartGlobal } from '@/redux/actions/globalActions';
import { addToWishlist } from '@/redux/actions/WishlistPage';
import { getSignInPageUrl } from '@/src/Utils/DKTLogin';
import { useRouter } from 'next/router';

const MyProfilePopUp = dynamic(() => import('@/src/pages/MyAccount/MyProfile/MyProfilePopUp'), {
  ssr: false,
});
const AddToCartPopup = dynamic(() => import('@/src/components/common/addItemToCartPopup/AddToCartPopup'), {
  ssr: false,
});

const robotoCondensedFonts = robotoCondensed({
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
});

const ToastMessage = dynamic(() => import('@/src/components/ToastMessage'), {
  ssr: false,
});

function Decathlon({ Component, ...rest }: AppLayoutProps) {
  const { store, props } = reduxStore.useWrappedStore(rest);
  const device = store?.getState()?.reducer?.device;
  const router = useRouter();

  useEffect(() => {
    gtmScript();
    const isLoggedIn = getCookie('dkt_isLoggedIn');

    if (isLoggedIn || window.sessionStorage.getItem('CleverTapload')) {
      loadCleverTap();
    } else {
      setTimeout(() => {
        loadCleverTap();
        window.sessionStorage.setItem('CleverTapload', 'true');
      }, 60000);
    }

    if (window.location.pathname !== '/payment') {
      store.dispatch(getUserLocation());
    }

    //set clickId for affiliate tracking
    const params = new URLSearchParams(window.location.search);
    const isClickId = params?.get('irclickid');
    if (isClickId) {
      setCookie('clickId', isClickId);
    }

    /* PWA Configuration */

    window.addEventListener('load', () => {
      if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator && props.platform !== 'APP') {
        const wb = new Workbox('/sw.js', { scope: '/' });
        wb.register();
      } else {
        console.warn('Progressive Web App support is disabled');
      }
    });

    window.addEventListener('online', () => {
      document.querySelector('html')?.removeAttribute('style');
      document.querySelector('#offline-message')?.remove();
    });

    window.addEventListener('offline', () => {
      // @ts-ignore
      document.querySelector('html').style = 'filter: grayscale(1); pointer-events: none;';
      document
        .querySelector('body')
        ?.insertAdjacentHTML(
          'beforeend',
          '<div id="offline-message" style="position: fixed;left: 0;bottom: 0;right: 0;height: 50px;z-index: 999999;background-color: #424353;color: #fff;display: flex;align-items: center;justify-content: center;font-size: 16px;">You are currently offline.</div>',
        );
    });

    window.dyAddToCart = async (modelId: number, isShowQuantitySelector: boolean, isHideColorOptions: boolean) => {
      return await store.dispatch(
        addItemToCartGlobal({
          modelId,
          showPopUp: true,
          addToCartProductId: modelId,
          showQuantitySelector: isShowQuantitySelector || false,
          hideColorOptions: isHideColorOptions || false,
        }),
      );
    };

    window.dyAddToWishlist = async (modelId: number) => {
      if (!isLoggedIn) {
        if (typeof window === 'object') {
          window.location.href = getSignInPageUrl();
          return true;
        }
        return;
      }
      const wishlistItems = window.localStorage.getItem('wishlistItems');
      const wishlistModelIds = wishlistItems ? JSON.parse(wishlistItems)?.modelIds : [];
      if (wishlistModelIds.includes(String(modelId))) {
        router.push('/wishlist');
      } else {
        const response = store.dispatch(addToWishlist([String(modelId)]));
        if (response) {
          return true;
        } else {
          return false;
        }
      }
    };

    AllPages();
  }, [store]);

  const getLayout = (device !== 'APP' && Component.getLayout) || ((page: any) => page);

  return (
    <div className={robotoCondensedFonts.className}>
      <Head>
        <meta name="theme-color" content="#424453" />
        <meta name="application-name" content="Decathlon Lite" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Decathlon Lite" />
        <meta name="description" content="Decathlon Sports India | Buy Sports Products Online" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/static/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#424453" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      </Head>

      <Provider store={store}>
        <DeviceContextProvider contextDevice={props.contextDevice}>
          {getLayout(<Component {...props.pageProps} />)}
          <MyProfilePopUp />
          <AddToCartPopup />
          <div model-placeholder="true" />
          <div deca-model-slide-placeholder="true" />
          <ToastMessage />
        </DeviceContextProvider>
      </Provider>
    </div>
  );
}

Decathlon.getInitialProps = reduxStore.getInitialPageProps((store) => async (appContext: any) => {
  const { req, res, platform, pageHost, contextDevice }: any = await getSSRConfig(appContext.ctx);
  const { dkt_isLoggedIn: isLoggedIn, _dyid, _dyid_server } = getCookies(appContext.ctx);
  const isServer = !!appContext.ctx?.req;
  const rewriteRoute = await rewriteRoutes(appContext.ctx, contextDevice);

  if (res && rewriteRoute) {
    res.writeHead(301, { Location: rewriteRoute });
    res.end();
    return {};
  }

  if (!_dyid_server && !!_dyid) {
    setCookie('_dyid_server', _dyid, {
      req,
      res,
      expires: new Date(new Date().getTime() + 31540000000000),
    });
  }

  await fetchFooter(appContext.ctx, store);
  store.dispatch(setDevice({ payload: platform }));
  store.dispatch(setContextDevice({ payload: contextDevice }));
  store.dispatch({
    type: 'SET_LOGIN_PAYLOAD',
    payload: isLoggedIn,
  });

  const pageProps = appContext.Component.getInitialProps
    ? await appContext.Component.getInitialProps({ ...appContext.ctx, isServer })
    : {};

  return {
    platform,
    pageHost,
    contextDevice,
    pageProps,
  };
});

export default Decathlon;
