/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-return-await */

import getContentful from '@/src/lib/contentful';
import fetchApi from '@/src/Middleware/fetchApi';
import { getCookie, getCookies } from '@/src/Utils/Cookies';
import safeStringify from 'fast-safe-stringify';
import { getProductUrl } from '../../src/Utils';
import axios from 'axios';

import { modelCardContent, modelCardPimContent, promiseSectionContent } from '../../constant/contentfulConstants';

let failureReason = '';

const errorHandler = (error, message) => {
  return {
    status: false,
    statusCode: error?.response?.status,
    message:
      error?.message === 'Network Error'
        ? 'No internet connection make sure wifi or cellular data is turned on, then try again.'
        : message || error?.message,
  };
};

export const fetchProductSiblings = async ({ ctx = {}, modelId }) => {
  const apiResponse = await axios
    .post(`${process.env.NEXT_PUBLIC_SITE_URL}/api/product/siblings`, { modelId })
    .then((response) => response?.data)
    .catch(() => []);

  if (apiResponse?.status && apiResponse?.products?.length > 0) {
    return apiResponse?.products;
  } else {
    failureReason = `PIM API Failure with statusCode:${apiResponse?.statusCode}, message:${apiResponse?.message} for modelId:${modelId} & productsLength: ${apiResponse?.products?.length}`;
  }
};

const setProductPageContentfulData = (modelId) => {
  return async (dispatch) => {
    let multiAddToCartProducts = {};
    let decaTabs = [];
    let sizeGuide = {};
    let promotion = [];
    let questionAnswers = [];

    dispatch({
      type: 'GET_PRODUCT_PAGE_DATA',
      payload: {
        decaTabs,
        promiseSection: [],
        sizeGuide,
        multiAddToCartProducts,
        promotion,
        questionAnswers,
      },
    });

    const productPageEntries = await getContentful({
      type: modelCardContent.type,
      include: 7,
      field: modelCardContent.key,
      value: modelId,
      select: modelCardContent.select,
    });

    if (productPageEntries?.items?.length > 0) {
      const fields = productPageEntries?.items?.[0]?.fields;

      const promotionList = JSON.parse(safeStringify(fields?.promotion || [])) || [];
      const date = new Date().getTime();

      multiAddToCartProducts = JSON.parse(safeStringify(fields?.frequentlyBrought?.fields || {})) || {};

      decaTabs = fields?.promiseDecatabs || [];

      questionAnswers = fields?.questionAnswers || [];

      sizeGuide = JSON.parse(safeStringify(fields?.sizeTemplate?.fields || {}));

      promotion = Array.isArray(promotionList)
        ? promotionList.filter(
            (item) =>
              item?.active === '1' &&
              item?.autoApply === '1' &&
              new Date(item?.dateFrom.replace(/-/g, '/')).getTime() <= date &&
              new Date(item?.dateTo.replace(/-/g, '/')).getTime() > date,
          )
        : [];
    }

    const promiseSection = await getContentful({
      type: promiseSectionContent.type,
      include: 7,
      field: promiseSectionContent.key,
      value: promiseSectionContent.field,
    });

    dispatch({
      type: 'GET_PRODUCT_PAGE_DATA',
      payload: {
        decaTabs,
        promiseSection: promiseSection.items,
        sizeGuide,
        multiAddToCartProducts,
        promotion,
        questionAnswers,
      },
    });
  };
};

const getProductPageDataV2 = ({ ctx = {}, productId }) => {
  return async (dispatch) => {
    const { query, asPath, isServer } = ctx;
    let modelId = false;

    if (!isServer) {
      dispatch({
        type: 'PRODUCT_PAGE_LOADER',
        payload: true,
      });
    }

    if (productId != undefined) {
      modelId = Number(productId);
    } else if (query.product.length >= 1) {
      modelId = Number(query.product[0]) || Number(query.product[0].split('_')[0]);
    }

    if (!Number.isInteger(modelId) || asPath.indexOf('anonymous') !== -1) {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          show404Page: true,
          redirectToOriginalUrl: false,
          failureReason: `modelId not found from the query: ${query.product?.[0]}`,
        },
      });
      return { redirectToOriginalUrl: false, show404Page: true, modelId };
    }

    if ((!query.id || !query.type) && isServer && productId === undefined) {
      const asPathDecode = decodeURIComponent(asPath);

      return {
        redirectToOriginalUrl: `${asPath}${asPathDecode.indexOf('?') !== -1 ? '&' : '?'}id=${modelId}&type=p`,
        isServer,
        show404Page: false,
        modelId,
      };
    }

    if (parseInt(query?.id, 10) !== parseInt(modelId, 10) && isServer && productId === undefined) {
      return {
        redirectToOriginalUrl: `${asPath?.split('?')?.[0]}?id=${modelId}&type=p`,
        isServer,
        show404Page: false,
        modelId,
      };
    }

    let productPageEntries = {};

    try {
      productPageEntries = await getContentful({
        type: modelCardPimContent.type,
        include: 7,
        field: modelCardPimContent.key,
        value: modelId,
        select: modelCardPimContent.select,
      });
    } catch (error) {}

    let activeProduct = {};
    let productsList = [];
    let selectedArticle = {};

    if (productPageEntries?.items?.length > 0) {
      const fields = productPageEntries?.items?.[0]?.fields;

      if (fields?.pimResponse?.length > 0) {
        activeProduct = fields?.pimResponse?.[0] || {};
        productsList = fields?.pimResponse;
      } else {
        const apiResponse = await fetchProductSiblings({ ctx, modelId });
        activeProduct = apiResponse?.[0] || {};
        productsList = apiResponse?.length > 1 ? apiResponse : [];
      }

      selectedArticle = activeProduct?.articles?.length <= 1 ? activeProduct?.articles?.[0] : {};
    } else {
      const apiResponse = await fetchProductSiblings({ ctx, modelId });
      activeProduct = apiResponse?.[0] || {};
      selectedArticle = activeProduct?.articles?.length <= 1 ? activeProduct?.articles?.[0] : {};
      productsList = apiResponse?.length > 1 ? apiResponse : [];
    }
    dispatch({
      type: 'GET_PRODUCT_PAGE_DATA',
      payload: {
        activeProduct,
        productsList,
        size:
          activeProduct?.articles?.find((defaultSize) => defaultSize.default === true) ||
          activeProduct?.articles?.[0] ||
          false,
        selectedArticle,
      },
    });

    const Location = getProductUrl(activeProduct);
    const productURI = productId != undefined ? `product/${productId}/` : `/p/${query.product.join('/')}`;
    const productQuery = asPath.replace(productURI, '');

    if ((!query.id || !query.type) && isServer && productId === undefined) {
      return {
        redirectToOriginalUrl: `${Location}${productQuery}?id=${modelId}&type=p`,
        isServer,
        show404Page: false,
        modelId,
        activeProduct,
      };
    }
    if (parseInt(query?.id, 10) !== parseInt(modelId, 10) && isServer && productId === undefined) {
      return {
        redirectToOriginalUrl: `${Location}${productQuery}?id=${modelId}&type=p`,
        isServer,
        show404Page: false,
        modelId,
        activeProduct,
      };
    }

    if (Object.values(activeProduct || {})?.length <= 0) {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          show404Page: true,
          redirectToOriginalUrl: false,
          failureReason,
        },
      });
      return { redirectToOriginalUrl: false, show404Page: true, modelId };
    }

    if (productURI !== Location && productId === undefined) {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          show404Page: false,
          redirectToOriginalUrl: `${Location}${productQuery || ''}`,
        },
      });
      if (isServer) {
        return {
          show404Page: false,
          redirectToOriginalUrl: `${Location}${productQuery || ''}`,
          modelId,
        };
      }
    }

    if (!isServer) {
      dispatch({
        type: 'PRODUCT_PAGE_LOADER',
        payload: false,
      });
    }

    return {
      modelId,
    };
  };
};

const getProductPageData = ({ ctx = {} }) => {
  return async (dispatch) => {
    const { query, asPath, isServer } = ctx;
    let modelId = false;

    if (!isServer) {
      dispatch({
        type: 'PRODUCT_PAGE_LOADER',
        payload: true,
      });
    }
    if (query.product?.length >= 1) {
      modelId = Number(query.product[0]) || Number(query.product[0].split('_')[0]);
    }

    if (!Number.isInteger(modelId) || asPath.indexOf('anonymous') !== -1) {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          show404Page: true,
          redirectToOriginalUrl: false,
          failureReason: `modelId not found from the query: ${query.product[0]}`,
        },
      });
      return { redirectToOriginalUrl: false, show404Page: true, modelId };
    }

    let productPageEntries = {};

    try {
      productPageEntries = await getContentful({
        type: modelCardContent.type,
        include: 7,
        field: modelCardContent.key,
        value: modelId,
        select: modelCardContent.select,
      });
    } catch (error) {}

    let activeProduct = {};
    let productsList = [];
    let selectedArticle = {};

    if (productPageEntries?.items?.length > 0) {
      const fields = productPageEntries?.items?.[0]?.fields;

      if (fields?.pimResponse?.length > 0) {
        activeProduct = fields?.pimResponse?.[0] || {};
        productsList = fields?.pimResponse;
      } else {
        const apiResponse = await fetchProductSiblings({ ctx, modelId });
        activeProduct = apiResponse?.[0] || {};
        productsList = apiResponse?.length > 1 ? apiResponse : [];
      }

      selectedArticle = activeProduct?.articles?.length <= 1 ? activeProduct?.articles?.[0] : {};
    } else {
      const apiResponse = await fetchProductSiblings({ ctx, modelId });
      activeProduct = apiResponse?.[0] || {};
      selectedArticle = activeProduct?.articles?.length <= 1 ? activeProduct?.articles?.[0] : {};
      productsList = apiResponse?.length > 1 ? apiResponse : [];
    }

    dispatch({
      type: 'GET_PRODUCT_PAGE_DATA',
      payload: {
        activeProduct,
        productsList,
        size:
          activeProduct?.articles?.find((defaultSize) => defaultSize.default === true) ||
          activeProduct?.articles?.[0] ||
          false,
        selectedArticle,
      },
    });

    const Location = getProductUrl(activeProduct);
    const productURI = `/p/${query.product.join('/')}`;
    const productQuery = asPath.replace(productURI, '');
    if ((!query.id || !query.type) && isServer) {
      return {
        redirectToOriginalUrl: `${Location}${productQuery}?id=${modelId}&type=p`,
        isServer,
        show404Page: false,
        modelId,
        activeProduct,
      };
    }
    if (parseInt(query?.id, 10) !== parseInt(modelId, 10) && isServer) {
      return {
        redirectToOriginalUrl: `${Location}${productQuery}?id=${modelId}&type=p`,
        isServer,
        show404Page: false,
        modelId,
        activeProduct,
      };
    }

    if (Object.values(activeProduct || {})?.length <= 0) {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          show404Page: true,
          redirectToOriginalUrl: false,
          failureReason,
        },
      });
      return { redirectToOriginalUrl: false, show404Page: true, modelId };
    }

    if (productURI !== Location) {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          show404Page: false,
          redirectToOriginalUrl: `${Location}${productQuery || ''}`,
        },
      });
      if (isServer) {
        return {
          show404Page: false,
          redirectToOriginalUrl: `${Location}${productQuery || ''}`,
          modelId,
        };
      }
    }

    if (!isServer) {
      dispatch({
        type: 'PRODUCT_PAGE_LOADER',
        payload: false,
      });
    }

    return {
      modelId,
    };
  };
};

const setProductPageData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_PRODUCT_PAGE_DATA',
      payload,
    });
  };
};

let cancelPreviousApis;

const fetchProductStock = () => {
  return async (dispatch, getState) => {
    // const { CancelToken } = axios;

    dispatch({
      type: 'TOGGLE_STOCK_LOADING',
      payload: true,
    });

    if (cancelPreviousApis) {
      cancelPreviousApis();
    }

    const { dkt_pincode: postalCode } = getCookies();
    const { activeProduct, selectedArticle } = getState()?.reducer?.productPage || {};
    const {
      articleIds,
      description,
      additionalShippingFee,
      additionalWeight,
      isDecathlonProduct = true,
    } = activeProduct || {};
    let { articles } = activeProduct || {};
    let skuArticleIds;
    let selectedArticleData =
      Object.values(selectedArticle || {})?.length > 0 ? selectedArticle : articles?.find((item) => item.default);
    selectedArticleData = Object.values(selectedArticleData || {})?.length > 0 ? selectedArticleData : articles?.[0];
    if (!isDecathlonProduct) {
      skuArticleIds = articles?.map((article) => article.skuArticleId);
    }

    const response = await fetchApi(null, `/api/product/stocks`, {
      data: {
        itemIds: isDecathlonProduct ? articleIds : skuArticleIds,
        items: [
          {
            additionalPackagingWeight: additionalWeight || 0,
            additionalShippingFee: additionalShippingFee || 0,
            ean: 'string',
            // ean: selectedArticle?.ean || 'string',
            itemDimension: {
              height: 1,
              length: 1,
              width: 1,
            },
            itemId: isDecathlonProduct ? selectedArticleData?.articleId : selectedArticleData?.skuArticleId,
            itemName: description?.productName || 'string',
            itemPrice: selectedArticleData?.priceForFront?.finalPrice,
            itemQty: 1,
            itemWeight: selectedArticleData?.weight?.weight || 1,
            type: 'product',
          },
        ],
        postalCode: postalCode || 560002,
        isDecathlon: isDecathlonProduct,
      },
    });

    if (response?.status) {
      articles = articles?.map((article) => {
        const articleStockPicture = response?.itemsStockPicture?.find((itemStock) =>
          isDecathlonProduct
            ? parseInt(itemStock?.itemId, 10) === article?.articleId
            : itemStock?.itemId === article?.skuArticleId,
        );

        return {
          ...article,
          fulfillmentCenter: articleStockPicture?.fulfilmentCenters?.[0]?.fulfillmentCenter,
          itemQuantity: articleStockPicture?.fulfilmentCenters?.[0]?.itemQuantity,
          isStockAvailable: articleStockPicture?.isStockAvailable || false,
        };
      });

      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          activeProduct: {
            ...activeProduct,
            articles,
          },
          isPODAvailable: response?.fulfilmentOptions?.homeDelivery?.[0]?.isPODAvailable,
          homeDelivery: response?.fulfilmentOptions?.homeDelivery?.[0]?.shipment?.[0]?.deliveryServices?.[0],
          collectOptions: response?.fulfilmentOptions?.collectOptions?.[0],
          lockerOptions: response?.fulfilmentOptions?.lockerOptions?.[0],
          fulfillerError: response?.errorDetails?.errorMessage || false,
          selectedArticle: articles?.length <= 1 ? articles?.[0] : selectedArticle,
        },
      });

      dispatch({
        type: 'GET_ZIP_DETAILS',
        payload: {
          zipcodeDetails: response?.zoneDetails,
        },
      });

      dispatch({
        type: 'TOGGLE_STOCK_LOADING',
        payload: false,
      });
    } else {
      dispatch({
        type: 'GET_PRODUCT_PAGE_DATA',
        payload: {
          homeDelivery: [],
          collectOptions: [],
          lockerOptions: [],
          fulfillerError: false,
          selectedArticle: {},
        },
      });
      dispatch({
        type: 'GET_ZIP_DETAILS',
        payload: {
          zipcodeDetails: '',
        },
      });

      dispatch({
        type: 'TOGGLE_STOCK_LOADING',
        payload: false,
      });
    }

    return null;
  };
};

const getReviews = ({ modelId, range }) => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_REVIEWS_LOADER',
      payload: true,
    });

    const apiResponse = await fetchApi(null, `/api/product/reviews`, {
      data: {
        modelId,
        range,
      },
    });

    if (apiResponse?.status) {
      // if (sessionStorage && apiResponse.reviews && apiResponse.token) {
      // 	sessionStorage.setItem('reviewToken', apiResponse.data.token);
      // }

      dispatch({
        type: 'GET_REVIEWS',
        payload: apiResponse.reviews ? apiResponse.reviews : [],
      });
    }

    dispatch({
      type: 'GET_REVIEWS_LOADER',
      payload: false,
    });

    return apiResponse;
  };
};

const setReviewsLoader = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'GET_REVIEWS_LOADER',
      payload,
    });
  };
};

const translateReviews = ({ id }) => {
  return async () => {
    const apiResponse = await fetchApi(null, `/api/product/reviews/translate-reviews?id=${id}`);
    if (apiResponse?.status) return apiResponse?.data;
  };
};

const toggleReviewPopup = (modelId = false) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_REVIEW_POPUP',
      payload: modelId,
    });
  };
};

const setDataToRedux = ({ decaTabs, promiseSection }) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_DATA_TO_REDUX',
      payload: {
        decaTabs,
        promiseSection,
      },
    });
  };
};

const getStoreStocks = (sort) => {
  return async (dispatch, getState) => {
    const { activeProduct, selectedArticle } = getState()?.reducer?.productPage || {};
    const pincode = getCookies('dkt_pincode');
    const state = getCookies('dkt_state');
    const apiResponse = await fetchApi(null, `/api/product/view-stores`, {
      data: {
        items: [
          {
            dimension: {
              height: 1,
              length: 1,
              width: 1,
            },
            ean: selectedArticle?.ean || activeProduct?.articles?.[0]?.ean || 'string',
            item_id: selectedArticle?.articleId || activeProduct?.articles?.[0]?.articleId,
            item_name: activeProduct?.description?.productName || 'string',
            price: activeProduct?.priceForFront?.finalPrice,
            quantity: 1,
            weight: selectedArticle?.weight?.weight || 1,
            type: 'product',
            additional_packaging_weight: activeProduct?.additionalWeight || 0,
            additional_shipping_fee: activeProduct?.additionalShippingFee || 0,
          },
        ],
        pincode: pincode || 560002,
        state: state || 'Karnataka',
        sort,
      },
    });
    return apiResponse;
  };
};

const getMultiCartData = (modelId) => {
  return async (dispatch) => {
    const modelCard = await getContentful({
      type: modelCardContent.type,
      include: 7,
      field: modelCardContent.key,
      value: modelId,
    });

    let payload = {
      getMultipleProduct: {},
    };
    if (modelCard?.items?.length > 0) {
      payload = {
        getMultipleProduct: modelCard?.items[0].fields?.frequentlyBrought?.fields || {},
      };
    }

    dispatch({
      type: 'SET_DATA_TO_MULTICART',
      payload,
    });

    return payload;
  };
};

const getProductsDetail = (modelIds) => {
  return async () => {
    const apiResponse = await fetchApi(null, '/api/product/multi-products', {
      data: {
        modelIds,
      },
    });
    if (apiResponse?.status) {
      return apiResponse?.products || [];
    } else {
      return [];
    }
  };
};

const getSpecificProductsDetail = (modelIds) => {
  return async (dispatch) => {
    let response = [];

    try {
      const productsDetail = await getContentful({
        type: 'modelCard',
        include: 7,
        field: 'fields.modelcode[in]',
        value: modelIds.toString(),
      });

      if (productsDetail && productsDetail?.items?.length) {
        response = modelIds?.map((modelid) => {
          return productsDetail?.items?.find((e) => {
            return e.fields?.modelcode === modelid;
          });
        });
      }
    } catch (error) {}
    return JSON.parse(safeStringify(response || []));
  };
};

export const getMultiBuyProductStock = (itemIds) => {
  return async (dispatch) => {
    const pincode = getCookie('dkt_pincode');
    const apiResponse = await fetchApi(null, '/api/product/multi-products-stock', {
      data: {
        itemIds,
        postalCode: pincode,
      },
    });
    if (apiResponse?.status) {
      return apiResponse?.stock || {};
    } else {
      return {};
    }
  };
};

export const setMultiProductsWithStock = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_MULTI_PRODUCTS',
      payload,
    });
  };
};

const setSelectedMultiProductData = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_SELECTED_MULTI_PRODUCT_DATA',
      payload,
    });
  };
};

const setMultiProductButtonClick = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_MULTI_PRODUCT_BUTTON_CLICK',
      payload,
    });
  };
};

const setGoToCartButtonVisibility = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_GO_TO_CART_BUTTON_VISIBILITY',
      payload,
    });
  };
};

export const setHitPersoReco = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'HIT_PERSORECO',
      payload,
    });
  };
};
const productPage = {
  getProductPageData,
  getProductPageDataV2,
  getReviews,
  setReviewsLoader,
  translateReviews,
  toggleReviewPopup,
  setDataToRedux,
  getStoreStocks,
  getMultiCartData,
  getProductsDetail,
  getMultiBuyProductStock,
  setMultiProductsWithStock,
  setSelectedMultiProductData,
  setProductPageData,
  fetchProductStock,
  setProductPageContentfulData,
  setMultiProductButtonClick,
  getSpecificProductsDetail,
  setGoToCartButtonVisibility,
  setHitPersoReco,
};

export default productPage;
