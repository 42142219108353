import fetchApi from '@/src/Middleware/fetchApi';

const getContentful = async ({ type, field, value, include, select } = {}) => {
  const response = await fetchApi(null, `/api/contentful`, {
    data: {
      type,
      field,
      include,
      value,
      select,
    },
  });

  return response;
};

export const getContentfulGraphQL = async ({ query } = {}) => {
  const response = await fetchApi(null, `/api/contentful/graphql`, {
    data: { query },
  });

  return response;
};

export default getContentful;
