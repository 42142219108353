import { GAEvent_getProductsData, GAPaymentCartDetailsTypes } from './GAhelpers';

/**
 * This function is triggered when the user proceeds to payment by clicking on "Pay Securely" button on the payment page. It dispatches actions to retrieve the data from the payment page Redux store(specifically from the payment reducer) and generates the necessary data for the 'add_payment_info'
 *
 * @param {string} paymentMethod - The mode of payment chosen during the payment
 */

export const GAEvent_proceedToPayment = (paymentMethod: string) => {
  const analyticsData = JSON.parse(window.localStorage.getItem('decathlon_cart_data') || '{}');
  const { cartLines, checkedCartItems } = analyticsData;

  try {
    const eventData = GAEvent_getProductsData({
      cartLines: checkedCartItems,
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'INR',
        value: cartLines?.cartTotal || '',
        coupon: cartLines?.couponCode || '',
        payment_type: paymentMethod,
        items: eventData?.GA4EventData,
      },
    });
  } catch {}
};

/**
 * This function is triggered when the payment fails
 *
 * @param {string} errorMethod - The error message which shows the reason for the payment failure.
 * @param {string} paymentMethod - The mode of payment chosen during the payment
 */

export const amplitudeEvent_paymentError = (errorMethod: string, paymentMethod: string) => {
  try {
    window.dataLayer.push({
      event: 'Payment Error Triggered',
      'error name': errorMethod || '',
      'payment method': paymentMethod || '',
    });
  } catch {}
};

/**
 * This function is triggered when the payment fails
 *
 * @param {string} timeTaken - Time taken to completely load the whole payment page.
 * @param {string} paymentOption - payment options loaded.
 */

export const amplitudeEvent_paymentMethodImpression = (timeTaken: string, paymentOption: string) => {
  try {
    window.dataLayer.push({
      event: 'Payment Page Loaded',
      'payment options displayed': paymentOption,
      'time taken to load': timeTaken,
    });
  } catch {}
};

/**
 * Triggered when the user lands on the payment page and the "Pay on Delivery" payment method is available
 */

export const analyticsEvent_PODEnabled = () => {
  try {
    window.dataLayer.push({
      event: 'pod_enabled',
    });
  } catch {}
};
