import fetchApi from '@/src/Middleware/fetchApi';
import { decrypt } from '@/src/Middleware/helpers';
import { getCookie, getCookies, removeCookie, setCookie } from '@/src/Utils/Cookies';
import { getSignInPageUrl } from '@/src/Utils/DKTLogin';
import Router from 'next/router';
import { getPlatform, jsBridgePaytm } from '../../src/Utils';
import IntentAppsJSON from '../../src/components/Payment/IntentBased/IntentApps.json';
import { GAEvent_localStorageData } from './GAEventsActions/globalEvents/GALocalStorage';
import { amplitudeEvent_paymentMethodImpression, GAEvent_proceedToPayment } from './GAEventsActions/paymentPageEvent';
import { GAEvent_purchaseComplete } from './GAEventsActions/purchaseCompleteEvent';
import { walletBalanceFetched } from './WalletPage';
import { hideToastMessage, showToastMessage } from './toastMessageActions';

const WEB_CART_TYPE = 'HOME_DELIVERY';

export const setPaymentLogsForApp = (ctx, paymentLogs) => () => {
  const { req, res } = ctx || {};
  setCookie('dkt_PaymentLogs', decodeURIComponent(JSON.stringify(paymentLogs)), {
    req,
    res,
    sameSite: 'none',
  });
};

export const paymentApiMapper =
  (ctx = null, url = null, payload = {}, retryPaymentWarningMessages = true) =>
  async (dispatch, getState) => {
    const isMultiSeller = getCookie('isMultiSeller');

    const { cartDetails, paymentSummaryDetails } = getState()?.paymentReducer;
    const isDecathlonPaymentPage = Router?.pathname === '/payment';
    const isMultiSellerCart =
      isMultiSeller === 'true' ||
      (isDecathlonPaymentPage ? cartDetails?.isMultiSeller : paymentSummaryDetails?.bottomCartDetail?.length > 1);

    const platform = getPlatform(ctx);

    const apiResponse = await fetchApi(ctx, url, {
      authenticationRequired: true,
      retry: 1,
      retryStatusCodes: [500],
      ...payload,
    });

    if (apiResponse?.statusCode === 'ERR_CANCELED') {
      return false;
    }

    if (apiResponse?.status) return { ...apiResponse, isDecathlonPaymentPage, isMultiSellerCart };

    if (platform === 'APP') {
      dispatch(setPaymentLogsForApp(ctx, apiResponse));
    }

    // Switch cases

    if (apiResponse?.statusCode === 401) {
      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Login expired',
          message: 'Please login again to proceed.',
          rightButtons: {
            status: true,
            text: platform === 'APP' ? 'Try again' : 'Login',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : getSignInPageUrl('payment'),
          },
        }),
      );
    } else if (url === '/api/payment/gift-card-validation') {
      return { ...apiResponse, isDecathlonPaymentPage, isMultiSellerCart, showErrorInsideModal: true };
    } else if (apiResponse?.statusCode === 406 || apiResponse?.errorCode === 'ERROR_STOCK') {
      const webRedirectionUrl = isMultiSellerCart ? '/payment-summary' : '/cart';

      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Few item/s ran out of stock',
          message: 'We have updated your cart',
          rightButtons: {
            status: true,
            color: 'yellow-full',
            backIcon: true,
            text: 'REVIEW CART',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : webRedirectionUrl,
          },
        }),
      );
    } else if (apiResponse?.errorCode === 'C001') {
      dispatch(
        showToastMessage({
          type: 'popup',
          message: 'Seems you have already paid for the order.',
          rightButtons: {
            status: true,
            text: 'Okay',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : '/',
          },
        }),
      );
    } else if (
      apiResponse?.statusCode === 409 ||
      ['C006', 'C007', 'C011', 'C013'].includes(apiResponse?.errorCode || ' ')
    ) {
      const webRedirectionUrl = isMultiSellerCart ? '/payment-summary' : '/cart';

      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Error Encountered!',
          message: 'Oops... something went wrong. Please review your cart',
          rightButtons: {
            status: true,
            color: 'yellow-full',
            backIcon: true,
            text: 'REVIEW CART',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : webRedirectionUrl,
          },
        }),
      );
    } else if (retryPaymentWarningMessages && (apiResponse?.statusCode === 400 || apiResponse?.statusCode >= 500)) {
      dispatch(
        showToastMessage({
          type: 'popup',
          message: 'Oops... something went wrong. Please retry payment',
          rightButtons: {
            status: true,
            text: 'Close',
          },
        }),
      );
    } else if (apiResponse?.statusCode === 417) {
      const webRedirectionUrl = isMultiSellerCart ? '/payment-summary' : '/cart';

      dispatch(
        showToastMessage({
          type: 'popup',
          message: 'Oops... something went wrong. Please review your cart',
          rightButtons: {
            status: true,
            color: 'yellow-full',
            backIcon: true,
            text: 'REVIEW CART',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : webRedirectionUrl,
          },
        }),
      );
    } else if (retryPaymentWarningMessages)
      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Error',
          message: apiResponse?.message || 'Some issues have occurred, please try again after some time!',
          rightButtons: {
            status: true,
            text: 'Okay',
          },
        }),
      );

    return { ...apiResponse, isDecathlonPaymentPage, isMultiSellerCart };
  };

export const cartApiMapper =
  (ctx = null, url = null, payload = {}) =>
  async (dispatch) => {
    const platform = getPlatform(ctx);
    // const response = await AxiosInstance(ctx, url, {
    // 	authenticationRequired: true,
    // 	retry: 1,
    // 	retryStatusCodes: [500],
    // 	...payload
    // });

    const response = await fetchApi(ctx, url, {
      authenticationRequired: true,
      retry: 1,
      retryStatusCodes: [500],
      ...payload,
    });

    if (response?.status) return response;

    if (response?.statusCode === 'ERR_CANCELED') {
      return false;
    }

    if (platform === 'APP') {
      dispatch(setPaymentLogsForApp(ctx, response));
    }

    if (response?.statusCode === 401) {
      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Login expired',
          message: 'Please login again to proceed.',
          rightButtons: {
            status: true,
            text: platform === 'APP' ? 'Try again' : 'Login',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : getSignInPageUrl('payment'),
          },
        }),
      );

      return response;
    }

    if (response?.statusCode === 406) {
      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Few item/s ran out of stock',
          message: 'We have updated your cart',
          rightButtons: {
            status: true,
            color: 'yellow-full',
            backIcon: true,
            text: 'REVIEW CART',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : '/cart',
          },
        }),
      );

      return response;
    }

    if (response?.errorCode === 'C001') {
      dispatch(
        showToastMessage({
          type: 'popup',
          message: 'Seems you have already paid for the order.',
          rightButtons: {
            status: true,
            text: 'Okay',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : '/',
          },
        }),
      );

      return response;
    }

    if (['C007', 'C011', 'C013'].includes(response?.errorCode || ' ')) {
      dispatch(
        showToastMessage({
          type: 'popup',
          // heading: 'Error Encountered!',
          message: response.message || 'Oops... something went wrong. Please review your cart',
          rightButtons: {
            status: true,
            color: 'yellow-full',
            backIcon: true,
            text: 'REVIEW CART',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : '/cart',
          },
        }),
      );

      return response;
    }

    dispatch(
      showToastMessage({
        type: 'popup',
        heading: 'Error',
        message: response.message || 'Some issues have occurred, please try again after some time!',
        rightButtons: {
          status: true,
          text: 'Okay',
          redirect: platform === 'APP' ? '/thankyou?error=exit' : '/cart',
        },
      }),
    );

    return response;
  };

export const paymentOptionsList = {
  WALLET: { key: 'PaymentOption_WALLET', name: 'WALLET' },
  UPI_INTENT: { key: 'PaymentOption_UPI_INTENT', name: 'UPI Intent' },
  UPI: { key: 'PaymentOption_UPI', name: 'UPI' },
  CARD: { key: 'PaymentOption_CARD', name: 'CARD' },
  PAY_LATER: { key: 'PaymentOption_PAY_LATER', name: 'PAY-LATER' },
  CRED: { key: 'PaymentOption_CRED', name: 'CRED' },
  NET_BANKING: { key: 'PaymentOption_NET_BANKING', name: 'NET-BANKING' },
  EMI: { key: 'PaymentOption_EMI', name: 'EMI' },
  GIFT_CARD: { key: 'PaymentOption_GIFT_CARD', name: 'GIFTCARD' },
  POD: { key: 'PaymentOption_POD', name: 'POD' },
};

export const setActivePaymentOption = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_PAYMENT_OPTION',
    payload,
  });
};

export const fetchPaymentDowntime = () => {
  return async (dispatch, getState) => {
    const { paymentAbortController } = getState()?.paymentReducer;
    const response = await dispatch(
      paymentApiMapper(
        null,
        '/api/payment/downtime',
        {
          signal: paymentAbortController?.signal,
        },
        false,
      ),
    );

    if (response?.status) {
      dispatch({
        type: 'PAYMENT_DOWNTIME_',
        payload: { downtime: response?.payment?.downtime },
      });
    }
  };
};

export const payLaterEligibility = ({ customerId, cartTotal }) => {
  return async (dispatch, getState) => {
    const { cartTotal: cartTotalFromRedux, paymentAbortController } = getState()?.paymentReducer;
    cartTotal = cartTotal || cartTotalFromRedux || 0;
    dispatch({
      type: 'PAY_LATER_ELIGIBILITY_LOADER',
      payload: true,
    });

    const response = await dispatch(
      paymentApiMapper(
        null,
        '/api/payment/paylater-eligibility',
        { data: { cartTotal, customerId }, signal: paymentAbortController?.signal },
        false,
      ),
    );

    dispatch({
      type: 'PAY_LATER_ELIGIBILITY',
      payload: response,
    });
  };
};

export const fetchPaymentInitApi = ({ sellerId }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'PAYMENT_INIT_LOADER',
      payload: true,
    });
    removeCookie('orderId');
    removeCookie('paymentMethod');

    const { paymentAbortController } = getState()?.paymentReducer;
    const response = await dispatch(
      paymentApiMapper(
        null,
        '/api/payment/init',
        { data: { sellerId }, signal: paymentAbortController?.signal },
        false,
      ),
    );
    const platform = getPlatform();

    if (response?.status) {
      const contact = platform === 'APP' ? getCookie('de_nm') : decrypt(getCookie('dkt_mn')); // response?.contact;
      const email = platform === 'APP' ? getCookie('de_em') : decrypt(getCookie('dkt_em')); // response?.email;
      let availableIntentApps = [];

      if (!contact || !email) {
        dispatch(
          showToastMessage({
            type: 'popup',
            // heading: 'Warning',
            message:
              'Some mandatory information is missing in your profile section. Please update your mobile number and email ID to continue with the purchase.',
            rightButtons: {
              status: true,
              text: platform === 'APP' ? 'Try again' : 'My Profile',
              redirect: platform === 'APP' ? '/thankyou?error=exit' : '/account/my-profile',
            },
          }),
        );

        return false;
      }

      const razorpayInit = await new Promise((resolve) => {
        let isGooglePayIntentAvailable = false;
        let isEligibleForCRED = false;
        let CREDEligibilityDescription = false;

        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/razorpay.js';
        script.id = 'razorpay-checkout-script';
        script.addEventListener('load', async () => {
          const razorpay = new window.Razorpay({
            key: response?.sellerDetails?.clientId,
            currency: 'INR',
            name: 'DECATHLON',
            prefill: {
              email,
              contact,
            },
            theme: {
              color: '#0082C3',
            },
          });

          isGooglePayIntentAvailable = await razorpay
            .checkPaymentAdapter('gpay')
            .then(() => true)
            .catch(() => false);

          await razorpay
            .checkCREDEligibility(`${contact}`)
            .then((res) => {
              isEligibleForCRED = true;
              CREDEligibilityDescription = res?.data?.offer?.description;
            })
            .catch((res) => {
              isEligibleForCRED = false;
              CREDEligibilityDescription = res?.error?.description;
            });

          await razorpay.once('ready', (RZPResponse) =>
            resolve({
              isGooglePayIntentAvailable,
              isEligibleForCRED,
              CREDEligibilityDescription:
                CREDEligibilityDescription || 'Pay using your saved credit cards for instant checkout',
              razorpayOptions: RZPResponse?.methods || {},
            }),
          );
        });

        document.body.appendChild(script);
      });

      const availableUPIOptionsLocal = window.sessionStorage.getItem('availableUPIOptions');

      if (window?.availableUPIOptions?.length > 0) {
        availableIntentApps = window.availableUPIOptions;
        window.sessionStorage.setItem('availableUPIOptions', window.availableUPIOptions);
      } else if (availableUPIOptionsLocal) {
        availableIntentApps = availableUPIOptionsLocal?.split(',');
      }

      dispatch(fetchPaymentDowntime());
      dispatch(payLaterEligibility({ customerId: response?.razorpay?.customerId }));

      await dispatch(walletBalanceFetched({ balance: response?.walletBalance }));
      await dispatch(updateRazorpayPaymentTotal());
      dispatch({
        type: 'PAYMENT_INIT',
        payload: {
          ...razorpayInit,
          razorpayKey: response?.sellerDetails?.clientId,
          customerTokens: response?.razorpay?.customerTokens || [],
          customerId: response?.razorpay?.customerId,
          contact,
          email,
          availableIntentApps,
          // availableIntentApps: [
          // 	'com.google.android.apps.nbu.paisa.user',
          // 	'com.phonepe.app',
          // 	'net.one97.paytm',
          // 	'in.amazon.mShop.android.shopping',
          // 	'com.whatsapp'
          // ]
        },
      });
    } else {
      dispatch({
        type: 'PAYMENT_INIT_LOADER',
        payload: false,
      });
    }
  };
};

export const fetchSequentialPaymentInitApis = ({ sellerId, paymentAbortController }) => {
  const getAvailableUPIOptions = () => {
    const availableUPIOptionsLocal = window.sessionStorage.getItem('availableUPIOptions')?.split(',');
    let availableUPIOptions = [];

    if (window?.availableUPIOptions?.length > 0) {
      availableUPIOptions = window?.availableUPIOptions;
    }

    if (availableUPIOptionsLocal?.length > 0) {
      availableUPIOptions = availableUPIOptionsLocal;
    }

    return IntentAppsJSON.some((item) => availableUPIOptions.includes(item.psp)) ? availableUPIOptions : [];
  };

  return async (dispatch, getState) => {
    const platform = getPlatform();
    const { dkt_loyaltyCard, card_number, isDecathlon, dkt_cartId } = getCookies();
    const customerId = platform === 'APP' ? card_number : decrypt(dkt_loyaltyCard);
    const contact = platform === 'APP' ? getCookie('de_nm') : decrypt(getCookie('dkt_mn')); // response?.contact;
    const email = platform === 'APP' ? getCookie('de_em') : decrypt(getCookie('dkt_em')); // response?.email;
    const cartType = platform === 'APP' ? getCookie('cartType') : WEB_CART_TYPE;

    removeCookie('orderId');
    removeCookie('paymentMethod');

    dispatch({
      type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
      payload: {
        isCartApiLoading: true,
        isWalletApiLoading: true,
        isCustomerApiLoading: true,
        isSellerApiLoading: true,
        isRazorpayOptionLoading: true,
        paymentAbortController,
        availableIntentApps: getAvailableUPIOptions(),
      },
    });

    if (!contact || !email) {
      dispatch(
        showToastMessage({
          type: 'popup',
          // heading: 'Warning',
          message:
            'Some mandatory information is missing in your profile section. Please update your mobile number and email ID to continue with the purchase.',
          rightButtons: {
            status: true,
            text: platform === 'APP' ? 'Try again' : 'My Profile',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : '/account/my-profile',
          },
        }),
      );

      return false;
    }

    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/razorpay.js';
    script.id = 'razorpay-checkout-script';

    let apiResponses = {
      cart: null,
      customer: null,
      wallet: null,
      seller: null,
    };

    const cartApiPromise = dispatch(
      cartApiMapper(null, '/api/payment/cart', {
        data: { customerId, sellerId, isDecathlon, cartType, fullCartResponse: !dkt_cartId },
        signal: paymentAbortController?.signal,
      }),
    ).then((response) => {
      apiResponses.cart = response;
      dispatch({
        type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
        payload: {
          isCartApiLoading: false,
          cartApiResponse: response,
        },
      });
      return response;
    });

    const customerApiPromise = dispatch(
      paymentApiMapper(null, '/api/payment/customer', { data: { sellerId }, signal: paymentAbortController?.signal }),
    ).then((response) => {
      apiResponses.customer = response;
      dispatch({
        type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
        payload: {
          isCustomerApiLoading: false,
          customerApiResponse: response,
          availableIntentApps: getAvailableUPIOptions(),
        },
      });
      return response;
    });

    Promise.all([cartApiPromise, customerApiPromise]).then(([cartApiResponse, customerApiResponse]) => {
      const customerId = customerApiResponse?.payment?.customerId;
      const cartTotal = cartApiResponse?.cartAmount + cartApiResponse?.shippingCharge;
      dispatch(payLaterEligibility({ customerId, cartTotal }));
    });

    dispatch(
      paymentApiMapper(
        null,
        '/api/payment/wallet',
        { data: { sellerId }, signal: paymentAbortController?.signal },
        false,
      ),
    ).then((response) => {
      apiResponses.wallet = response;
      dispatch(walletBalanceFetched({ balance: response?.payment?.walletBalance }));
      dispatch({
        type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
        payload: {
          isWalletApiLoading: false,
          availableIntentApps: getAvailableUPIOptions(),
        },
      });
    });
    dispatch(
      paymentApiMapper(null, '/api/payment/seller', { data: { sellerId }, signal: paymentAbortController?.signal }),
    ).then((response) => {
      apiResponses.seller = response;
      script.addEventListener('load', async () => {
        const razorpay = new window.Razorpay({
          key: response?.payment?.sellerDetails?.clientId,
          currency: 'INR',
          name: 'DECATHLON',
          prefill: {
            email,
            contact,
          },
          theme: {
            color: '#0082C3',
          },
        });

        razorpay
          .checkPaymentAdapter('gpay')
          .then(() =>
            dispatch({
              type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
              payload: {
                isGooglePayIntentAvailable: true,
              },
            }),
          )
          .catch();

        razorpay
          .checkCREDEligibility(`${contact}`)
          .then((res) =>
            dispatch({
              type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
              payload: {
                credEligibility: { status: true, description: res?.data?.offer?.description },
              },
            }),
          )
          .catch((res) =>
            dispatch({
              type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
              payload: {
                credEligibility: { status: false, description: res?.error?.description },
              },
            }),
          );

        razorpay.once('ready', (RZPResponse) => {
          dispatch({
            type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
            payload: {
              razorpayOptions: RZPResponse?.methods,
              isRazorpayOptionLoading: false,
            },
          });
        });
      });
      document.body.appendChild(script);

      dispatch({
        type: 'SEQUENTIAL_PAYMENT_INIT_ACTION',
        payload: {
          isSellerApiLoading: false,
          sellerApiResponse: response?.payment,
          contact,
          email,
        },
      });
    });

    dispatch(fetchPaymentDowntime());

    await new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (apiResponses?.cart && apiResponses?.customer && apiResponses?.wallet && apiResponses?.seller) {
          clearInterval(interval);
          dispatch(updateRazorpayPaymentTotal());
          resolve();

          function GA_AMPLITUDE_PAYMENT_DETAILS_FUNC() {
            const { loadEventEnd, navigationStart } = window.performance.timing;
            const loadTime = loadEventEnd - navigationStart;
            let paymentOptionsAvailable = '';

            const availableOptionsValue = [
              'UPI',
              'CREDIT/DEBIT CARDS',
              'NETBANKING',
              'BUY NOW PAY LATER',
              'EMI',
              'PAY ON DELIVERY',
            ];

            const availableOptions = [
              'Instant payment using UPI app',
              'Visa, MasterCard, RuPay & More',
              'All Indian Banks',
              'Interest Free Monthly Installments',
              'Cards, Cardless EMI & More',
              'UPI / Card on doorstep ',
            ];

            setTimeout(() => {
              availableOptions.forEach((option, i) => {
                if (document.body.textContent.includes(option)) {
                  paymentOptionsAvailable += ` | ${availableOptionsValue[i]}`;
                }
              });

              amplitudeEvent_paymentMethodImpression(loadTime / 1000, paymentOptionsAvailable);
            }, 1000);
          }

          GA_AMPLITUDE_PAYMENT_DETAILS_FUNC();
        }
      }, 100);
    });
  };
};

export const fetchCart = () => {
  return async (dispatch) => {
    const { dkt_loyaltyCard, cartTotal, card_number, sellerId, isDecathlon, dkt_cartId } = getCookies();
    const platform = getPlatform();
    const customerId = platform === 'APP' ? card_number : decrypt(dkt_loyaltyCard);
    const cartType = platform === 'APP' ? getCookie('cartType') : WEB_CART_TYPE;

    if (platform === 'APP' && !customerId) {
      dispatch({
        type: 'CART_DETAILS',
        payload: { status: true, cartAmount: +cartTotal, shippingCharge: 0 },
      });

      return {
        status: true,
      };
    }

    dispatch({
      type: 'CART_DETAILS_LOADER',
      payload: true,
    });

    const response = await dispatch(
      cartApiMapper(null, '/api/payment/cart', {
        data: { customerId, sellerId, isDecathlon, cartType, fullCartResponse: !dkt_cartId },
      }),
    );

    dispatch({
      type: 'CART_DETAILS',
      payload: { ...response, paymentAbortController },
    });

    return response;
  };
};

export const fetchPaymentSummary = (ctx) => {
  return async (dispatch) => {
    dispatch({
      type: 'PAYMENT_SUMMARY_DETAILS_LOADER',
      payload: true,
    });
    const platform = getPlatform();

    const response = await fetchApi(ctx, '/api/payment/summary');

    // const response = await AxiosInstance(ctx, '/api/payment/summary');

    // console.log(response);

    if (response?.status) {
      setCookie('dkt_cartId', response.paymentSummary.cartId);
      dispatch({
        type: 'PAYMENT_SUMMARY_DETAILS_LOADER',
        payload: false,
      });
      dispatch({
        type: 'PAYMENT_SUMMARY_DETAILS',
        payload: { paymentSummaryDetails: response.paymentSummary, paymentSummaryDetailsLoader: false },
      });
    } else {
      if (response?.statusCode === 401) {
        dispatch(
          showToastMessage({
            type: 'popup',
            heading: 'Login expired',
            message: 'It seems like your login session has expired, please login again to proceed.',
            rightButtons: {
              status: true,
              text: platform === 'APP' ? 'Try again' : 'Login',
              redirect: platform === 'APP' ? '/thankyou?error=exit' : getSignInPageUrl('payment-summary'),
            },
          }),
        );
      } else {
        dispatch(
          showToastMessage({
            type: 'popup',
            heading: 'Error Encountered!',
            message: response?.message || 'Some issues have occurred, please try again after some time!',
            rightButtons: {
              status: true,
              text: 'Review Cart',
              redirect: '/cart',
            },
          }),
        );
      }

      dispatch({
        type: 'PAYMENT_SUMMARY_DETAILS',
        payload: { paymentSummaryDetails: {}, paymentSummaryDetailsLoader: false },
      });
    }
  };
};

export const fetchPaymentStatus = ({ orderId, sellerId, retryApi = false, retryApiDelay = 5000 }) => {
  return async (dispatch, getState) => {
    if (retryApi) {
      dispatch(
        showToastMessage({
          type: 'progress-bar',
          message: 'Please wait, We are confirming your payment status.',
        }),
      );
    }

    const { paymentAbortController } = getState()?.paymentReducer;
    const platform = getPlatform();
    const response = await dispatch(
      paymentApiMapper(null, '/api/payment/status', { data: { orderId }, signal: paymentAbortController?.signal }),
    );
    const isDecathlonPaymentPage = response?.isDecathlonPaymentPage;
    const isMultiSellerCart = response?.isMultiSellerCart;
    setCookie('isMultiSeller', isMultiSellerCart ? 'true' : 'false');

    if (!retryApi) return response;
    const { dkt_loyaltyCard, dkt_cartId, dkt_memberId, dkt_pincode } = getCookies();
    sellerId = sellerId || getCookie('sellerId');

    if (response?.status) {
      if (response?.payment?.status === 'SUCCESS') {
        dispatch(hideToastMessage());

        if (isMultiSellerCart) {
          if (Router?.pathname !== '/payment-summary') {
            if (platform === 'APP') {
              window.location = '/thankyou';
              return false;
            }

            Router.push('/payment-summary');
          } else {
            dispatch(fetchPaymentSummary());
          }
          GAEvent_purchaseComplete();
        } else {
          if (platform === 'APP') {
            window.location = '/thankyou';
            return false;
          }

          Router.push('/thankyou');
        }
      } else if (response?.payment?.status === 'FAILED') {
        const isPaymentVerificationPage = Router?.pathname === '/payment-verification';

        dispatch(
          showToastMessage({
            type: 'popup',
            heading: 'Payment Processing',
            message:
              'It seems like your payment is not captured. Please check your Order & Returns section after some time',
            leftButtons: isPaymentVerificationPage
              ? {
                  status: true,
                  text: 'Try again',
                  redirect: platform === 'APP' ? '/thankyou?error=exit' : '/payment',
                }
              : {
                  status: true,
                  text: 'Close',
                },
            rightButtons: {
              status: true,
              text: 'Order & Returns',
              redirect: '/account/orders-returns',
            },
          }),
        );
      } else if (retryApi) {
        setTimeout(() => dispatch(fetchPaymentStatus({ orderId, sellerId, retryApi, retryApiDelay })), retryApiDelay);
      }
    }

    return response;
  };
};

export const fetchPaymentStatusForOrder = (orderId) => {
  return async (dispatch) => {
    dispatch(
      showToastMessage({
        type: 'progress-bar',
        message: 'Please wait, We are confirming payment status.',
      }),
    );

    dispatch(
      fetchPaymentStatus({
        orderId,
        retryApi: true,
        retryApiDelay: 4000,
      }),
    );
  };
};

const getGoogleAnalyticId = ({ platform = null }) => {
  const { _gid, _ga, googleAnalyticId } = getCookies();

  if (platform === 'APP') {
    return googleAnalyticId || _gid || null;
  }

  return _ga ? _ga.split('.').splice(2).join('.') : null;
};

export const setAnalyticsDataAction =
  ({ method, orderId } = {}) =>
  async (dispatch, getState) => {
    const paymentReducer = getState()?.paymentReducer;
    const isDMI = paymentReducer?.paymentSummaryDetailsLoader;

    let cartData = {};
    if (isDMI) {
      cartData = paymentReducer?.cartDetails;
    } else {
      cartData = paymentReducer?.paymentSummaryDetails?.bottomCartDetail?.find((detail) => !detail.isDecathlon);
    }

    const fulfiller = isDMI
      ? cartData?.cartParts?.some((part) => part?.partType === 'WH_HOME_DELIVERY')
        ? 'Home Delivery'
        : 'Pickup from Store'
      : 'Home Delivery';

    const sellingPrice = isDMI ? paymentReducer?.cartTotal : cartData?.cartTotal || 0;

    const taxAmount = isDMI ? cartData?.cartTaxAmount : 'N/A';

    const couponCode = isDMI ? cartData?.couponCode : 'N/A';

    let shippingCharge = isDMI
      ? cartData?.shippingCharge > 0 && !cartData?.isFreeShipping
        ? cartData?.shippingCharge
        : 'Free'
      : cartData?.shippingCost;

    let noOfProductsInCart = isDMI ? cartData?.cartParts?.[0]?.lines : cartData?.cartLines?.length;

    let cartLines = [];
    if (isDMI) {
      paymentReducer?.cartDetails?.cartParts?.forEach((cartPart) => {
        cartLines = [...cartLines, cartPart?.lines];
      });
    } else {
      cartData?.cartLines?.forEach((cartLine) => {
        cartLines.push(cartLine);
      });
    }

    const cartItemsIds = [];
    cartLines &&
      cartLines.map((item) => {
        if (
          !cartItemsIds.some((cartItem) => cartItem.modelCode === item.modelCode && cartItem.itemCode === item.itemCode)
        ) {
          cartItemsIds.push({ modelCode: item.modelCode, itemCode: item.itemCode });
        }
      });

    let cartType;
    if (isDMI) {
      cartType = paymentReducer?.isMultiSeller ? 'Mixed' : 'Decathlon';
    } else {
      cartType = 'Marketplace';
    }

    let cartTotal = isDMI ? paymentReducer?.cartTotal : cartData?.cartTotal || 0;
    let couponAmount = isDMI ? cartData?.discountAmount : 0;
    let discountAmount = isDMI ? cartData?.saveAmount + cartData?.discountAmount : cartData?.cartAmountWithoutVoucher;
    let isCouponApplied = couponAmount === 0 ? false : true;

    GAEvent_localStorageData({
      paymentData: {
        orderId,
        method,
        cartItemsIds,
        fulfiller,
        sellingPrice,
        taxAmount,
        couponCode,
        shippingCharge,
        noOfProductsInCart,
        cartType,
        cartTotal,
        couponAmount,
        discountAmount,
        isCouponApplied,
      },
    });
    setCookie('orderId', orderId, {
      expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 2),
    });
  };

export const createPaymentRazorPay = ({ method, paymentMethod } = {}) => {
  return async (dispatch, getState) => {
    dispatch(
      showToastMessage({
        type: 'loading',
      }),
    );

    const {
      cartTotal,
      giftCardPaymentTotal,
      giftCards,
      razorpayPaymentTotal,
      cartDetails,
      razorpayKey,
      email,
      contact,
      customerId,
      paymentAbortController,
    } = getState()?.paymentReducer;

    const { useWalletBalanceInPayment, balanceAmount } = getState()?.wallet || {};

    const { sellerId, isDecathlon } = getCookies();

    if (cartTotal <= 0) {
      return {
        status: false,
        message: 'To proceed, please ensure that your order value is greater than the voucher value.',
      };
    }

    GAEvent_proceedToPayment(paymentMethod);

    const platform = getPlatform();

    let walletPaymentTotal = useWalletBalanceInPayment
      ? balanceAmount < cartTotal
        ? Math.floor(balanceAmount)
        : cartTotal
      : 0;

    let razorpayPaymentTotalAmount = razorpayPaymentTotal;

    if (paymentMethod === 'PAY_ON_DELIVERY') {
      razorpayPaymentTotalAmount += Number(cartDetails?.podConvenienceFee) || 0;
    }

    const controller = new AbortController();

    setTimeout(() => {
      controller.abort();
    }, 1000);

    const cartType = platform === 'APP' ? getCookie('cartType') : WEB_CART_TYPE;

    const response = await dispatch(
      paymentApiMapper(null, '/api/payment/create', {
        signal: controller.signal,
        data: {
          method,
          isBnpl: paymentMethod === 'Axio Pay Later',
          gid: getGoogleAnalyticId({ platform }),
          isDecathlon,
          sellerId,
          cartType,
          ...(walletPaymentTotal > 0 && { walletPaymentTotal }),
          ...(giftCardPaymentTotal > 0 && {
            giftCardPaymentTotal,
            giftCards: giftCards.filter((card) => card.usedBalance > 0),
          }),
          ...(razorpayPaymentTotal > 0 && { razorpayPaymentTotal: razorpayPaymentTotalAmount }),
        },
        signal: paymentAbortController?.signal,
      }),
    );

    if (window.location.pathname !== '/payment') {
      return { status: false };
    }

    if (response?.status) {
      const { orderId, razorpay } = response.payment;

      if (platform !== 'APP') {
        setCookie('orderId', orderId);
        dispatch(setAnalyticsDataAction({ method: paymentMethod, orderId }));
      } else {
        setCookie('orderId', orderId, { sameSite: 'none' });
        setCookie('paymentMethod', paymentMethod, { sameSite: 'none' });
      }

      if (method === 'UPI_QR') {
        dispatch(hideToastMessage());
        return response;
      }

      if (
        paymentMethod === 'WALLET' ||
        paymentMethod === 'GIFTCARD' ||
        paymentMethod === 'WALLET_GIFTCARD' ||
        paymentMethod === 'PAY_ON_DELIVERY'
      ) {
        dispatch(fetchPaymentStatusForOrder(response?.payment?.orderId));

        return {
          ...response,
          status: true,
        };
      }

      if (method === 'RAZORPAY_INTENT') {
        if (!razorpay.razorpayIntentLink) {
          showToastMessage({
            type: 'popup',
            heading: 'Error Encountered!',
            message:
              'We are sorry, an unexpected error occurred while generating UPI intent link. Please try again later.',
            rightButtons: {
              status: true,
              text: 'Okay',
            },
          });

          return {
            ...response,
            status: false,
          };
        }

        return {
          ...response,
          status: true,
          orderId,
          deepLink: razorpay.razorpayIntentLink,
        };
      }

      dispatch(
        showToastMessage({
          type: 'progress-bar',
          message: 'Your payment is initiated, please complete your payment.',
        }),
      );

      return {
        ...response,
        razorpayConfiguration: {
          key: razorpayKey,
          currency: 'INR',
          name: 'DECATHLON',
          prefill: {
            email,
            contact,
          },
          notes: razorpay?.notes,
          theme: {
            color: '#0082C3',
          },
          redirect: platform !== 'WEB',
          callback_url: `${window.location.origin}/payment-verification?platform=${getCookie('decathlonApp') || 'WEB'}`,
        },
        createPaymentConfiguration: {
          amount: Number(razorpay.amount) * 100,
          email,
          contact,
          order_id: razorpay.orderId,
          customer_id: customerId,
        },
        onPaymentSuccess: (paymentResponse) => {
          if (paymentResponse?.razorpay_signature) {
            dispatch(
              fetchPaymentStatus({
                orderId: response?.payment?.orderId,
                retryApi: true,
                retryApiDelay: 4000,
              }),
            );
          }
        },
        onPaymentFailed: (paymentResponse) => {
          dispatch(
            showToastMessage({
              type: 'popup',
              heading: 'Payment Failed',
              message: paymentResponse?.error?.description,
              rightButtons: {
                status: true,
                text: 'Okay',
              },
            }),
          );
        },
      };
    }

    return response;
  };
};

const updateRazorpayPaymentTotal = () => (dispatch, getState) => {
  const { cartTotal, giftCardPaymentTotal } = getState()?.paymentReducer;
  const { balanceAmount, useWalletBalanceInPayment } = getState()?.wallet;
  const walletPaymentTotal = useWalletBalanceInPayment
    ? balanceAmount < cartTotal
      ? Math.floor(balanceAmount)
      : cartTotal
    : 0;

  const razorpayPaymentTotal = cartTotal - walletPaymentTotal - giftCardPaymentTotal;
  const disablePaymentConfirmationModal = balanceAmount < 1 && giftCardPaymentTotal === 0;
  return dispatch({
    type: 'RAZORPAY_PAYMENT_UPDATE',
    payload: { razorpayPaymentTotal, disablePaymentConfirmationModal },
  });
};

let staleGiftCards = [];

export const updateGiftCardAndRazorpayPaymentsTotals = (giftCards) => async (dispatch, getState) => {
  const cartTotal = getState()?.paymentReducer?.cartTotal;
  const { balanceAmount, useWalletBalanceInPayment } = getState()?.wallet;
  const { activePaymentOption, cartDetails } = getState()?.paymentReducer;
  const walletPaymentTotal = useWalletBalanceInPayment
    ? balanceAmount < cartTotal
      ? Math.floor(balanceAmount)
      : cartTotal
    : 0;
  const remainingPaymentTotal = cartTotal - walletPaymentTotal;
  const device = getState()?.reducer?.contextDevice;
  if (!giftCards?.length) giftCards = getState()?.paymentReducer?.giftCards;

  let giftCardsTotalAmountUsed = 0;

  const giftCardsUpdated = giftCards.map((card) => {
    if (activePaymentOption === paymentOptionsList.POD.key && !useWalletBalanceInPayment && cartDetails?.isPODEnabled) {
      card.usedBalance = 0;
      return card;
    }

    card.usedBalance =
      remainingPaymentTotal - giftCardsTotalAmountUsed > card.balance
        ? Math.floor(card.balance)
        : remainingPaymentTotal - giftCardsTotalAmountUsed;
    giftCardsTotalAmountUsed += card.usedBalance;
    return card;
  });

  const giftCardPaymentTotal = giftCards.reduce(
    (usedBalanceTotal, giftCard) => usedBalanceTotal + giftCard.usedBalance,
    0,
  );

  dispatch({
    type: 'GIFTCARD_UPDATE',
    payload: { giftCards: giftCardsUpdated, giftCardPaymentTotal },
  });

  if (
    device !== 'mobile' &&
    activePaymentOption === paymentOptionsList.POD.key &&
    cartDetails?.isPODEnabled &&
    staleGiftCards?.length !== giftCards?.length
  ) {
    dispatch(setActivePaymentOption(paymentOptionsList.UPI.key));
  }
  staleGiftCards = giftCards;
  await dispatch(updateRazorpayPaymentTotal());
};

export const removeGiftCard = (payload) => async (dispatch) => {
  dispatch({
    type: 'GIFTCARD_REMOVE',
    payload,
  });
  return dispatch(updateGiftCardAndRazorpayPaymentsTotals());
};

export const addNewGiftCard =
  ({ number, transactionId, balance, pin }) =>
  async (dispatch, getState) => {
    let giftCards = getState()?.paymentReducer?.giftCards;
    const updatedGiftCards = giftCards.map((card) => ({ ...card }));
    updatedGiftCards.push({
      number,
      transactionId,
      balance,
      usedBalance: 0,
      pin,
    });
    return await dispatch(updateGiftCardAndRazorpayPaymentsTotals(updatedGiftCards));
  };

export const createPaymentShopInShop = ({ cartTotal, isDecathlon, sellerId }) => {
  return async (dispatch) => {
    dispatch(
      showToastMessage({
        type: 'loading',
      }),
    );

    const platform = getPlatform();

    const initResponse = await dispatch(paymentApiMapper(null, '/api/payment/init'));

    if (initResponse?.status) {
      const response = await dispatch(
        paymentApiMapper(null, '/api/payment/create', {
          data: {
            method: platform,
            gid: getGoogleAnalyticId({ platform }),
            cartTotal,
            sellerId,
            isDecathlon,
          },
        }),
      );

      if (response?.status) {
        const contact = decrypt(getCookie('dkt_mn')); // response?.contact;
        const email = decrypt(getCookie('dkt_em')); // response?.email;

        if (platform === 'PHONEPE_SWITCH') {
          dispatch(setAnalyticsDataAction({ method: 'PhonePe Switch', orderId: response?.payment?.orderId }));

          const razorpayCreatePayment = new window.Razorpay({
            key: process.env.RAZORPAY_KEY,
            currency: 'INR',
            name: 'DECATHLON',
            prefill: {
              email,
              contact,
            },
            notes: response?.payment?.razorpay?.notes,
            theme: {
              color: '#0082C3',
            },
            redirect: true,
            callback_url: `${window.location.origin}/payment-verification`,
          });
          razorpayCreatePayment.createPayment({
            amount: Number(response?.payment?.razorpay?.amount) * 100,
            email,
            contact,
            order_id: response?.payment?.razorpay?.orderId,
            customer_id: initResponse?.razorpay?.customerId,
            method: 'wallet',
            wallet: 'phonepeswitch',
          });
        } else if (platform === 'PAYTM_MINI') {
          const payment = await jsBridgePaytm('paytmPayment', {
            amount: String(response?.payment?.razorpay?.amount),
            orderId: response?.payment?.razorpay?.orderId,
            txnToken: response?.payment?.paytm?.txnToken,
            mid: process.env.PAYTM_MID,
          });

          const { STATUS, RESPMSG } = JSON.parse(payment.data);

          if (STATUS === 'TXN_SUCCESS' || STATUS === 'PENDING') {
            dispatch(
              fetchPaymentStatus({
                orderId: response?.payment?.orderId,
                retryApi: true,
                retryApiDelay: 4000,
              }),
            );
          } else {
            dispatch(
              showToastMessage({
                type: 'popup',
                heading: 'Payment failed!',
                message: RESPMSG,
                rightButtons: {
                  status: true,
                  text: 'Try again',
                },
              }),
            );
          }

          dispatch(setAnalyticsDataAction({ method: 'Paytm Mini', orderId: response?.payment?.orderId }));
        }
      }
      return response;
    }

    return initResponse;
  };
};

export const createStandardPaymentRazorPay = ({ cartTotal, sellerId, isDecathlon }) => {
  return async (dispatch, getState) => {
    dispatch(
      showToastMessage({
        type: 'progress-bar',
        message: "You are being redirected to the seller's payment page to complete your payment.",
      }),
    );

    const platform = getPlatform();

    const response = await dispatch(
      paymentApiMapper(null, '/api/payment/create', {
        data: {
          method: 'STANDARD',
          gid: getGoogleAnalyticId({ platform }),
          razorpayPaymentTotal: cartTotal,
          sellerId,
          isDecathlon,
          cartType: WEB_CART_TYPE,
        },
      }),
    );

    if (response?.status) {
      const cookies = getCookies();
      const analyticsCartData = JSON.parse(window?.localStorage?.getItem('decathlon_cart_data') || '{}')?.cartLines;
      window.dataLayer.push({
        event: 'Payment_Redirect',
        eventCategory: 'Payment Page Redirect',
        eventAction: 'Sellers Option Available',
        eventLabel: sellerId,

        dimension25: decrypt(cookies?.dkt_loyaltyCard) || null,
        dimension53: cookies.dkt_cartId,
        dimension88: decrypt(cookies?.dkt_memberId) || null,
        dimension89: cookies.dkt_pincode,
        dimension103:
          analyticsCartData?.deliveryType === 'HOME_DELIVERY'
            ? 'Home delivery'
            : analyticsCartData?.deliveryType === 'PICKUP'
              ? 'Pickup from store'
              : '',
        dimension186: (analyticsCartData && analyticsCartData?.createdBy) || '',
      });

      const contact = decrypt(getCookie('dkt_mn')); // response?.contact;
      const email = decrypt(getCookie('dkt_em')); // response?.email;
      let paymentError = false;

      dispatch(setAnalyticsDataAction({ method: 'Standard Checkout Web', orderId: response?.payment?.orderId }));

      const razorpayCreatePayment = new window.Razorpay({
        key: response?.payment?.sellerDetails?.clientId,
        currency: 'INR',
        name: response?.sellerDetails?.sellerName,
        prefill: {
          email,
          contact,
        },
        order_id: response?.payment?.razorpay?.orderId,
        customer_id: response?.payment?.customerId,
        notes: response?.payment?.razorpay?.notes,
        theme: {
          color: '#0082C3',
        },
        handler: async (paymentResponse) => {
          if (paymentResponse?.razorpay_signature) {
            dispatch(
              fetchPaymentStatus({
                orderId: response?.payment?.orderId,
                sellerId,
                retryApi: true,
                retryApiDelay: 4000,
              }),
            );
          }
        },
        modal: {
          ondismiss() {
            if (paymentError) {
              window &&
                window?.dataLayer.push({
                  event: 'Payment_Failed',
                  eventCategory: 'Payment Status',
                  eventAction: 'Payment Failed',
                  eventLabel: sellerId,
                  dimension25: decrypt(cookies?.dkt_loyaltyCard) || null,
                  dimension53: cookies.dkt_cartId,
                  dimension88: decrypt(cookies?.dkt_memberId) || null,
                  dimension89: cookies.dkt_pincode,
                  dimension103:
                    analyticsCartData?.deliveryType === 'HOME_DELIVERY'
                      ? 'Home delivery'
                      : analyticsCartData?.deliveryType === 'PICKUP'
                        ? 'Pickup from store'
                        : '',
                  dimension186: (analyticsCartData && analyticsCartData?.createdBy) || '',
                });
              dispatch(
                showToastMessage({
                  type: 'popup',
                  heading: 'Payment Failed',
                  message: paymentError,
                  rightButtons: {
                    status: true,
                    text: 'Close',
                    onClick: () => {
                      dispatch(hideToastMessage());
                      dispatch(fetchPaymentSummary());
                    },
                  },
                }),
              );
            } else {
              dispatch(
                showToastMessage({
                  type: 'popup',
                  // heading: 'Payment Failed',
                  message: "It's seems like you have closed the payment screen.",
                  rightButtons: {
                    status: true,
                    text: 'Close',
                    // redirect: '/cart'
                  },
                }),
              );
            }
          },
        },
      }).open();
      razorpayCreatePayment.on('payment.error', (paymentResponse) => {
        paymentError = paymentResponse?.error?.description;
      });
    }
    return response;
  };
};

export const paymentSummaryPageUnmountAction = () => (dispatch) => {
  dispatch({
    type: 'PAYMENT_SUMMARY_PAGE_UNMOUNT',
  });
};

export const paymentPageUnmountAction = () => (dispatch) => {
  dispatch({
    type: 'PAYMENT_PAGE_UNMOUNT',
  });
};

export const createStandardGiftCard = (_giftCardData) => {
  function convertEmptyToNull(data) {
    if (Array.isArray(data)) {
      return data.map((item) => convertEmptyToNull(item));
    } else if (data !== null && typeof data === 'object') {
      return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, convertEmptyToNull(value)]));
    } else if (data === '') {
      return null;
    }
    return data;
  }

  return async (dispatch, getState) => {
    const platform = getPlatform();
    const giftCardData = convertEmptyToNull(_giftCardData);

    dispatch(
      showToastMessage({
        type: 'progress-bar',
        message: 'You are being redirected to the payment page to complete your payment.',
      }),
    );

    const response = await fetchApi(null, '/api/payment/gift-card-payment', { data: giftCardData });

    if (response?.status) {
      const contact = platform === 'APP' ? getCookie('de_nm') : decrypt(getCookie('dkt_mn')); // response?.contact;
      const email = platform === 'APP' ? getCookie('de_em') : decrypt(getCookie('dkt_em')); // response?.email;

      if (platform === 'APP') {
        const giftcardPageURL = new URL(window.location.href);
        giftcardPageURL.searchParams.set('paymentView', 'true');
        window.history.replaceState({}, '', giftcardPageURL);
      }

      setCookie('orderId', response?.giftcard?.orderId, {
        expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
      });

      let paymentError = false;

      const razorpayCreatePayment = new window.Razorpay({
        key: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
        currency: 'INR',
        name: 'Decathlon Sports India',
        prefill: {
          contact: contact || null,
          email: email || null,
        },
        order_id: response?.giftcard?.razorpayOrderId,
        notes: response?.giftcard?.notes,
        theme: {
          color: '#0082C3',
        },
        webview_intent: true,
        redirect: platform !== 'WEB',
        callback_url: `${window.location.origin}/payment-verification?platform=${getCookie('decathlonApp') || 'WEB'}&giftcard=true`,
        handler: async (paymentResponse) => {
          if (paymentResponse?.razorpay_signature) {
            window.location = '/thankyou';
          }
        },
        modal: {
          ondismiss() {
            const giftcardPageURL = new URL(window.location.href);
            giftcardPageURL.searchParams.delete('paymentView');
            window.history.replaceState({}, '', giftcardPageURL);

            if (paymentError) {
              dispatch(
                showToastMessage({
                  type: 'popup',
                  heading: 'Payment Failed',
                  message: paymentError,
                  rightButtons: {
                    status: true,
                    text: 'Close',
                    onClick: () => {
                      dispatch(hideToastMessage());
                    },
                  },
                }),
              );
            } else {
              dispatch(
                showToastMessage({
                  type: 'popup',
                  // heading: 'Payment Failed',
                  message: "It's seems like you have closed the payment screen.",
                  rightButtons: {
                    status: true,
                    text: 'Close',
                  },
                }),
              );
            }
          },
        },
      }).open();
      razorpayCreatePayment.on('payment.error', (paymentResponse) => {
        paymentError = paymentResponse?.error?.description;
      });
    } else {
      console.log('response status not right');
    }
    return response;
  };
};
