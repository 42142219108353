import { UnknownAction } from 'redux';
import { initialStateTypes } from './giftCardTypes';

const initialState: initialStateTypes = {
  contentFulData: {
    title: null,
    description: null,
    desktopImageUrl: null,
    imageUrl: null,
    value: [],
    themeData: {
      themeImageUrl: '',
      themeDesktopImageUrl: '',
      themeTitle: '',
      themeSubTitle: '',
      themeDescription: '',
      themeSubDescription: '',
    },
  },
  savedGiftCard: [],
  accordiondIndex: null,
  showGiftCardForm: true,
  buyForOthers: true,
  previewBuyForOthers: null,
  previewData: {},
  constantGCValue: {
    senderName: null,
    senderEmail: null,
  },
  isPaymentButtonDisabled: false,
};

export default function giftCardReducer(state = initialState, action: UnknownAction) {
  switch (action.type) {
    case 'GC_DATA_FETCHED': {
      return {
        ...state,
        contentFulData: action?.payload,
      };
    }

    case 'GC_SAVED_DATA': {
      return {
        ...state,
        savedGiftCard: [...state?.savedGiftCard, action?.payload],
        previewBuyForOthers: null,
      };
    }

    case 'GC_UPDATE_DETAILS': {
      const savedGiftCard = state.savedGiftCard.map((value, index) => {
        if (index === state?.accordiondIndex) {
          if (state?.previewBuyForOthers === null) {
            return { ...value, ...action?.payload };
          } else {
            return { ...value, ...action?.payload, gcType: state?.previewBuyForOthers };
          }
        } else {
          if (value?.gcType === 'self') {
            return {
              ...value,
              receiverEmail: state?.constantGCValue?.senderEmail,
            };
          } else {
            return {
              ...value,
              senderEmail: state?.constantGCValue?.senderEmail,
              senderFirstName: state?.constantGCValue?.senderName,
            };
          }
        }
      });

      return {
        ...state,
        savedGiftCard,
        previewBuyForOthers: null,
      };
    }

    case 'GC_ACCORDION_INDEX': {
      return {
        ...state,
        accordiondIndex: action?.payload,
        previewBuyForOthers: null,
      };
    }

    case 'GC_DELETE_SAVED_GIFTCARD': {
      const savedGiftCard = state?.savedGiftCard?.filter((_, i) => i !== state.accordiondIndex);
      const isAnyOtherGCisAvailable = savedGiftCard.find((item) => item.gcType === 'Others');

      if (!isAnyOtherGCisAvailable) {
        return {
          ...state,
          savedGiftCard,
          previewBuyForOthers: null,
          constantGCValue: {
            senderName: null,
            senderEmail: null,
          },
        };
      } else {
        return {
          ...state,
          savedGiftCard,
          previewBuyForOthers: null,
        };
      }
    }

    case 'GC_TOGGLE_SHOW_FORM_TO_FALSE': {
      return {
        ...state,
        showGiftCardForm: false,
      };
    }

    case 'GC_TOGGLE_SHOW_FORM_TO_TRUE': {
      return {
        ...state,
        showGiftCardForm: true,
        accordiondIndex: null,
        previewBuyForOthers: null,
      };
    }

    case 'GC_BUY_FOR_OTHERS': {
      return {
        ...state,
        buyForOthers: action?.payload,
      };
    }

    case 'GC_SET_PREVIEW_DATA': {
      return {
        ...state,
        previewData: action?.payload,
      };
    }

    case 'RESET_PREVIEW_DATA': {
      return {
        ...state,
        previewData: {},
      };
    }

    case 'SET_PREVIEW_BUY_FOR_OTHERS': {
      return {
        ...state,
        previewBuyForOthers: action?.payload,
      };
    }

    case 'SET_CONSTANT_GC_VALUE': {
      return {
        ...state,
        constantGCValue: action?.payload,
      };
    }

    case 'PAYMENT_BUTTON': {
      return {
        ...state,
        isPaymentButtonDisabled: action?.payload,
      };
    }

    default:
      return state;
  }
}
