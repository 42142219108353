const { NEXT_PUBLIC_ENVIRONMENT: environment } = process?.env;

const baseurlAddressApis = process?.env?.[`${environment}_API_DOSA_ADDRESS`];
const baseurlCartApis = process?.env?.[`${environment}_API_DOSA_CART`];
const baseurlMyStoreApis = process?.env?.[`${environment}_API_DOSA_MYSTORE`];
const baseurlNotifyMeApis = process?.env?.[`${environment}_API_DOSA_NOTIFYME`];
const baseurlPaymentApis = process?.env?.[`${environment}_API_DOSA_PAYMENT`];
const baseurlPersoRecoApis = process?.env?.[`${environment}_API_DOSA_PERSORECO`];
const baseurlPimApis = process?.env?.[`${environment}_API_DOSA_PIM`];
const baseurlPurchaseHistoryApis = process?.env?.[`${environment}_API_DOSA_PURCHASEHISTORY`];
const baseurlPurchaseApis = process?.env?.[`${environment}_API_DOSA_PURCHASE`];
const baseurlWishlistApis = process?.env?.[`${environment}_API_DOSA_WISHLIST`];
const baseurlAmiReturnsApis = process?.env?.[`${environment}_API_OMS_AMI_RETURNS`];
const baseurlFulfillerApis = process?.env?.[`${environment}_API_OMS_FULFILLER`];
const baseurlOMSIndiaApis = process?.env?.[`${environment}_API_OMS_INDIA`];
const baseurlCouponsApis = process?.env?.[`${environment}_API_COUPONS`];
const baseurlReviewsApis = process?.env?.[`${environment}_API_REVIEWS`];
const baseurlQwikcilverApis = process?.env?.[`${environment}_API_QWIKCILVER`];
const baseurlOnePayApis = process?.env?.[`${environment}_API_ONEPAY`];
const baseurlWalletApis = process?.env?.[`${environment}_API_WALLET`];
const baseurlEmployeeDiscount = process?.env?.[`${environment}_API_EMPLOYEE_DISCOUNT`];
const baseurlMountBank = process?.env?.[`${environment}_API_MOUNT_BANK`];
const baseurlGiftCard = process?.env?.[`${environment}_API_GIFTCARD_PAYMENT`];

const constants = {
  baseurlEmployeeDiscount: {
    discountDetails: `${baseurlEmployeeDiscount}/v1/employeeDetails`,
  },

  baseurlAddressApis: {
    V1: {
      addresses: `${baseurlAddressApis}/api/v1/addresses`,
      addressesWithDynamicParams: `${baseurlAddressApis}/api/v1/addresses/@`,
    },
    V2: {
      addresses: `${baseurlAddressApis}/api/v2/addresses`,
      addressesWithDynamicParams: `${baseurlAddressApis}/api/v2/addresses/@`,
    },
  },
  baseurlCartApis: {
    V8: {
      cart: `${baseurlCartApis}/v8/cart`,
      viewCart: `${baseurlCartApis}/v8/cart/view_cart`,
      cartMerge: `${baseurlCartApis}/v8/cart/merge`,
      cartPay: `${baseurlCartApis}/v8/cart/pay`,
      paymentCheckoutPage: (cartId) => `${baseurlCartApis}/v8/cart/payment_checkout_page/${cartId}`,
      cartLines: `${baseurlCartApis}/v8/cart/lines`,
      cartLineClear: `${baseurlCartApis}/v8/cart/line/clear`,
      cartCompleteClear: `${baseurlCartApis}/v8/cart/complete/clear`,
      cartLineCount: `${baseurlCartApis}/v8/cart/cart_line/count`,
      cartLineWithDynamicParams: `${baseurlCartApis}/v8/cart/cart_line/@`,
      convenience: `${baseurlCartApis}/v8/cart/convenience`,
      postalCode: `${baseurlCartApis}/v8/cart/postal_code`,
      cartCoupon: `${baseurlCartApis}/v8/cart/coupon`,
      updateAddress: `${baseurlCartApis}/v8/cart/updateaddress`,
      cartFulfillOption: `${baseurlCartApis}/v8/cart/fulfill_option`,
      paymentSummary: `${baseurlCartApis}/v8/cart/payment_summary`,
    },
  },
  baseurlMyStoreApis: {
    V1: {
      catchments: `${baseurlMyStoreApis}/api/v1/catchments`,
      catchmentsWithDynamicParams: `${baseurlMyStoreApis}/api/v1/catchments/@`,
    },
  },
  baseurlNotifyMeApis: {
    V1: {
      notifications: `${baseurlNotifyMeApis}/v1/notifications`,
    },
    V2: {
      notifications: `${baseurlNotifyMeApis}/v2/notifications`,
    },
  },
  baseurlPaymentApis: {
    V1: {
      virtualAccountsWithDynamicParams: `${baseurlPaymentApis}/api/v1/payments/virtual_accounts/@`,
      init: `${baseurlPaymentApis}/api/v1/payments/init`,
      statusWithDynamicParams: `${baseurlPaymentApis}/api/v1/payments/@/status`,
      giftCardPayment: `${baseurlGiftCard}/v1/purchase`,
    },
    V2: {
      virtualAccountsWithDynamicParams: `${baseurlPaymentApis}/api/v2/payments/virtual_accounts/@`,
      init: `${baseurlPaymentApis}/api/v2/payments/init`,

      iinWithDynamicParams: `${baseurlPaymentApis}/api/v2/payments/iin/@`,
      payments: `${baseurlPaymentApis}/api/v2/payments`,
      downtimes: `${baseurlPaymentApis}/api/v2/payments/downtimes/`,
      vpa: `${baseurlPaymentApis}/api/v2/payments/vpa/`,
      paylaterEligiblity: `${baseurlPaymentApis}/api/v2/payments/paylater_eligiblity/`,
    },
    V3: {
      virtualAccountsWithDynamicParams: `${baseurlPaymentApis}/api/v3/payments/virtual_accounts/@`,
      init: `${baseurlPaymentApis}/api/v3/payments/init`,
      statusWithDynamicParams: `${baseurlPaymentApis}/api/v3/payments/@/status`,
      paylaterEligiblity: `${baseurlPaymentApis}/api/v3/payments/paylater_eligiblity/`,
    },
    V4: {
      iinWithDynamicParams: `${baseurlPaymentApis}/api/v4/payments/iin/@`,
      payments: `${baseurlPaymentApis}/api/v4/payments`,
      downtimes: `${baseurlPaymentApis}/api/v4/payments/downtimes/`,
      vpa: `${baseurlPaymentApis}/api/v4/payments/vpa/`,
    },
    V5: {
      payments: `${baseurlPaymentApis}/api/v5/payments`,
    },
    V6: {
      init: `${baseurlPaymentApis}/api/v6/payments/init`,
      payments: `${baseurlPaymentApis}/api/v6/payments`,
      giftCardValidation: `${baseurlPaymentApis}/api/v6/payments/giftCardValidation`,
      paymentOrderWithDynamicParams: (orderId, isDecathlon) =>
        `${baseurlPaymentApis}/api/v6/payments/${orderId}/isDecathlon/${isDecathlon}`,
      initCustomer: (sellerId) => `${baseurlPaymentApis}/api/v6/payments/initCustomer?sellerId=${sellerId}`,
      initSellerDetails: (sellerId) => `${baseurlPaymentApis}/api/v6/payments/initSellerDetails?sellerId=${sellerId}`,
      initWallet: `${baseurlPaymentApis}/api/v6/payments/initWallet`,
    },
  },
  baseurlPersoRecoApis: {
    V1: {
      floors: `${baseurlPersoRecoApis}/api/v1/recentlyViewedProduct/`,
    },
    V2: {
      floors: `${baseurlPersoRecoApis}/api/v2/floors`,
    },
    V3: {
      floors: `${baseurlPersoRecoApis}/api/v3/floors`,
    },
  },
  baseurlPimApis: {
    V1: {
      siteMapWithDynamicParams: `${baseurlPimApis}/v1/@/siteMap`,
      categoryWithDynamicParams: `${baseurlPimApis}/v1/categories/category/@`,
      models: `${baseurlPimApis}/v1/catalog/models`,
      modelsWithDynamicParams: `${baseurlPimApis}/v1/catalog/models/@`,
      modelsInfoWithDynamicParams: `${baseurlPimApis}/v1/catalog/models/@/info`,
      siblingsWithDynamicParams: `${baseurlPimApis}/v1/catalog/models/@/siblings`,
      modelsDsm: `${baseurlPimApis}/v1/catalog/modelsDsm`,
    },
  },
  baseurlPurchaseHistoryApis: {
    V2: {
      purchases: `${baseurlPurchaseHistoryApis}/api/v2/purchases`,
      purchasesAllOrders: `${baseurlPurchaseHistoryApis}/api/v2/purchase/all_orders`,
      purchasesWithDynamicParams: `${baseurlPurchaseHistoryApis}/api/v2/purchases/@`,
      purchasesWithDynamicParamsAmi: `${baseurlPurchaseHistoryApis}/api/v2/purchases/@/isDecathlon/@`,
      purchasesTracking: `${baseurlPurchaseHistoryApis}/api/v2/purchases/tracking/@`,
      purchasesTrackingAmi: `${baseurlPurchaseHistoryApis}/api/v2/purchases/tracking/@/isDecathlon/@`,
    },
    V3: {
      purchases: `${baseurlPurchaseHistoryApis}/api/v3/purchases`,
      purchasesWithDynamicParams: `${baseurlPurchaseHistoryApis}/api/v3/purchases/@`,
      purchasesTracking: `${baseurlPurchaseHistoryApis}/api/v3/purchases/tracking/@`,
    },
    V5: {
      purchases: `${baseurlPurchaseHistoryApis}/api/v5/purchases`,
      purchasesAllOrders: `${baseurlPurchaseHistoryApis}/api/v5/purchase/all_orders`,
      purchasesWithDynamicParams: `${baseurlPurchaseHistoryApis}/api/v5/purchases/@`,
      purchasesWithDynamicParamsAmi: (orderId, isDecathlon) =>
        `${baseurlPurchaseHistoryApis}/api/v5/purchases/${orderId}/isDecathlon/${isDecathlon}`,
      purchasesTracking: `${baseurlPurchaseHistoryApis}/api/v5/purchases/tracking/@`,
      purchasesTrackingAmi: `${baseurlPurchaseHistoryApis}/api/v5/purchases/tracking/@/isDecathlon/@`,
    },
    V6: {
      purchasesAllOrders: `${baseurlPurchaseHistoryApis}/api/v6/purchase/all_orders`,
    },
  },
  baseurlWishlistApis: {
    V1: {
      createWishlist: `${baseurlWishlistApis}/api/v1/create_wishlist`,
    },
    V2: {
      createWishlist: `${baseurlWishlistApis}/api/v2/create_wishlist`,
    },
    V5: {
      deleteAll: `${baseurlWishlistApis}/api/v5/deleteAll`,
      getWishlist: `${baseurlWishlistApis}/api/v5/getWishlist`,
      getAllWishlist: `${baseurlWishlistApis}/api/v5/getAllWishlist`,
      modelById: `${baseurlWishlistApis}/api/v5/delete/modelById`,
    },
    V6: {
      deleteAll: `${baseurlWishlistApis}/api/v6/deleteAll`,
      getWishlist: `${baseurlWishlistApis}/api/v6/getWishlist`,
      getAllWishlist: `${baseurlWishlistApis}/api/v6/getAllWishlist`,
      modelById: `${baseurlWishlistApis}/api/v6/delete/modelById`,
    },
  },
  baseurlAmiReturnsApis: {
    V1: { amiReturnHistory: `${baseurlAmiReturnsApis}/v1/@/returnHistroy` },
    V2: {
      amiReturnWithDynamicParams: `${baseurlAmiReturnsApis}/v2/amiReturn/@`,
      amiItemReturnDetailsWithDynamicParams: `${baseurlAmiReturnsApis}/v2/viewReturnProduct/@`,
    },
  },
  baseurlFulfillerApis: {
    V1: {
      state: `${baseurlFulfillerApis}/v1/state`,
      stateNames: `${baseurlFulfillerApis}/v1/state_names`,
      zoneWithDynamicParams: `${baseurlFulfillerApis}/v1/zone/@`,
    },
    V2: {
      collectOptions: `${baseurlFulfillerApis}/v2/collect_options`,
    },
    V3: {
      getRepexFulfilmentOptions: `${baseurlFulfillerApis}/v3/fulfilment_options/replacement_exchange`,
      checkout: `${baseurlFulfillerApis}/v3/fulfilment_options/checkout`,
      multiItems: `${baseurlFulfillerApis}/v3/stock_picture/multi_items`,
      fetchSimilarProducts: `${baseurlFulfillerApis}/v3/similar_products`,
    },
    V5: {
      productPageStocks: (isDecathlon) =>
        `${baseurlFulfillerApis}/v5/fulfilment_options/product_page?isDecathlon=${isDecathlon}&sort=leadtime&locale=in`,
    },
  },
  baseurlOMSIndiaApis: {
    V1: {
      refundModesWithDynamicParams: `${baseurlOMSIndiaApis}/return/api/v1/refund_modes/@`,
      returnWithDynamicParams: `${baseurlOMSIndiaApis}/return/api/v1/return/@`,
      returnHistory: `${baseurlOMSIndiaApis}/return/api/v1/@/returnHistroy`,
      serviceAbility: `${baseurlOMSIndiaApis}/tms/api/v1/return/serviceability`,
      checkExchangeable: (orderPartId, orderLineId, returnId) =>
        `${baseurlOMSIndiaApis}/order/replacement/api/v1/replacement_exchange/enabled/orderPart/${orderPartId}/orderLine/${orderLineId}?returnId=${returnId}`,
      fetchReplaceTracking: (id, returnId) =>
        `${baseurlOMSIndiaApis}/order/replacement/api/v1/orderPart/${id}/tracking?returnId=${returnId}`,
      getExchangeReasons: (orderId, itemCode) =>
        `${baseurlOMSIndiaApis}/order/replacement/api/v1/reasons/itemCode/${itemCode}/reasonsType/REPLACEMENT_EXCHANGE?orderId=${orderId}`,
      checkReplaceable: `${baseurlOMSIndiaApis}/order/replacement/api/v1/replacement_serviceability`,
      getReplacementSummary: `${baseurlOMSIndiaApis}/order/replacement/api/v1/summary`,
      createReplacement: `${baseurlOMSIndiaApis}/order/replacement/api/v1`,
      cancelReplacement: (newPartId) => `${baseurlOMSIndiaApis}/order/replacement/api/v1/${newPartId}/order_cancel`,
      cancelReturn: (returnId) => `${baseurlOMSIndiaApis}/return/api/v1/cancel/return/${returnId}`,
      createFundAccount: (orderPartId) => `${baseurlOMSIndiaApis}/return/api/v1/fund_accounts/${orderPartId}`,
    },
    V2: {
      refundModesWithDynamicParams: `${baseurlOMSIndiaApis}/return/api/v2/refund_modes/@`,
      checkReplaceable: `${baseurlOMSIndiaApis}/order/replacement/api/v2/replacement_serviceability`,
      checkExchangeable: (orderPartId, orderLineId, returnId) =>
        `${baseurlOMSIndiaApis}/order/replacement/api/v2/replacement_exchange/enabled/orderPart/${orderPartId}/orderLine/${orderLineId}?returnId=${returnId}`,
      fetchReplaceTracking: (id, returnId) =>
        `${baseurlOMSIndiaApis}/order/replacement/api/v2/orderPart/${id}/tracking?returnId=${returnId}`,
      getExchangeReasons: (orderId, itemCode) =>
        `${baseurlOMSIndiaApis}/order/replacement/api/v2/reasons/itemCode/${itemCode}/reasonsType/REPLACEMENT_EXCHANGE?orderId=${orderId}`,
      exchangeServiceability: `${baseurlOMSIndiaApis}/order/replacement/api/v2/exchange_serviceability`,
      returnWithDynamicParams: `${baseurlOMSIndiaApis}/return/api/v2/return/@`,
      deliveryFeedback: `${baseurlOMSIndiaApis}/order/api/v2/delivery_feedback`,
      getCancellationReasons: (orderPartId) =>
        `${baseurlOMSIndiaApis}/order/api/v2/cancellation/reasons/${orderPartId}`,
      checkCancelReplacementServiceability: `${baseurlOMSIndiaApis}/order/cancel/replacement/api/v1/cancel_replacement_serviceability`,
      createCancelReplacement: `${baseurlOMSIndiaApis}/order/cancel/replacement/api/v1/create`,
      orderCancel: `${baseurlOMSIndiaApis}/order/api/v2/order_cancel`,
      invoiceQRWithDynamicParams: `${baseurlOMSIndiaApis}/order/api/v2/invoice/qr/@`,
      invoiceWithDynamicParams: `${baseurlOMSIndiaApis}/order/api/v2/invoice/@`,
    },
    V3: {
      orderCancel: `${baseurlOMSIndiaApis}/order/api/v3/order_cancel`,
      fetchSimilarProducts: `${baseurlOMSIndiaApis}/fulfiller/api/v3/similar_products`,
    },
  },
  baseurlCouponsApis: {
    V1: {
      couponsList: `${baseurlCouponsApis}/v1/couponsList`,
    },
    V2: {
      couponsList: `${baseurlCouponsApis}/v2/couponsList`,
    },
  },
  baseurlPurchaseApis: {
    V1: {
      customerWithDynamicParams: (range) => `${baseurlPurchaseApis}/v1/members/associations?range=${range}`,
    },
  },
  baseurlReviewsApis: {
    V2: {
      list: `${baseurlReviewsApis}/openvoice/legacy/en_IN/v2/review/list`,
    },
    V4: {
      getReviews: (modelId, range, rating) =>
        `${baseurlReviewsApis}/openvoice/api/v4/decathlon.product:${modelId}?range=${range}${rating ? '&rating=' + rating : ''}`,
      translateReviews: (reviewId) => `${baseurlReviewsApis}/openvoice/api/v4/reviews/${reviewId}/translate`,
    },
    post: `${baseurlReviewsApis}/openvoice/legacy/post/en_IN/review/post`,
    vote: `${baseurlReviewsApis}/openvoice/legacy/post/en_IN/review/vote`,
    translate: `${baseurlReviewsApis}/openvoice/legacy/en_IN/review/translate/@/dktin`,
  },
  baseurlQwikcilverApis: {
    V3: {
      authorize: `${baseurlQwikcilverApis}QwikCilver/XnP/api/v3/authorize`, // gift card authorization
      validate: `${baseurlQwikcilverApis}QwikCilver/XnP/api/v3/gc/transactions/validate`, // gift card validation
    },
  },
  baseurlOnePayApis: {
    giftCard: `${baseurlOnePayApis}/onepay/eu/v2/payments/11/actions/do_check`, // giftcard
    hook: `${baseurlOnePayApis}/dsi_dosa_payment/api/v1/webhook/onepay`,
  },
  baseurlWalletApis: {
    create: `${baseurlWalletApis}/v1/create`,
    balance: `${baseurlWalletApis}/v2/balance`,
    load: `${baseurlWalletApis}/v1/load`,
    history: `${baseurlWalletApis}/v2/history`,
  },
  baseurlMountBank: {
    V1: {
      token: (email) => `${baseurlMountBank}/v1/connect/auth/token?email=${email}`,
      profile: `${baseurlMountBank}/v1/members/profile/me`,
    },
  },
};

/**
 * To attach dynamic value to API end point
 * @param {String} api API end point
 * @param {String} value dynamic value
 * @returns {String} new API end point with dynamic value
 */
constants.withDynamicParams = (api = '', value = '') => {
  return api.replace('@', value);
};

/**
 * To attach query paramter to API end point
 * @param {String} api api API end point
 * @param {String} query query parameter
 * @returns {String} new API end point with query parameter
 */
constants.withQueryParams = (api = '', query = {}) => {
  return `${api}?${Object.entries(query)
    .map((item) => item.join('='))
    .join('&')}`;
};

module.exports = constants;
