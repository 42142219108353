import * as wcmsOptimization from '@/src/wcmsOptimization';
import {
  appSettingContent,
  megaMenuContent,
  modelCardContent,
  newMegaMenuContent,
} from '@/constant/contentfulConstants';
import getContentful from '@/src/lib/contentful';

import fetchApi from '@/src/Middleware/fetchApi';
import { getContentfulClientEntries } from '../../src/Utils';

const Toggle = () => {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE',
    });
  };
};

const toggleVerticalNavigation = () => {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_VERTICAL_NAVIGATION',
    });
  };
};

const toggleReturnSlide = () => {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_RETURN_SLIDE',
    });
  };
};

const toggledeliveryterms = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'DELIVERY_TERMS',
      payload: payload !== null ? payload : null,
    });
  };
};

const togglelogin = (device, isCartPage = false, islocationPrompt = false) => {};

const toggleVideoFrame = (videoId) => {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_VIDEO_FRAME',
      payload: videoId,
    });
  };
};

const toggleAddNewAddress = () => {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_ADD_NEW_ADDRESS',
    });
  };
};

const suggestionSearch = (suggestionSearchtxt) => {
  return (dispatch) => {
    return dispatch({
      type: 'SEARCH_SUGGESTIONS',
      payload: suggestionSearchtxt,
    });
  };
};

const showRecentSearches = (isRecentSuggestions) => {
  return (dispatch) => {
    return dispatch({
      type: 'RECENT_SEARCH_SUGGESTIONS',
      payload: isRecentSuggestions,
    });
  };
};

const hideShowSuggestions = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'SEARCH_SUGGESTIONS_HIDE_SHOW',
      payload,
    });
  };
};

const hideShowTopProducts = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'SEARCH_TOP_PRODUCTS_HIDE_SHOW',
      payload,
    });
  };
};

const numberTopProducts = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'NUMBER_TOP_PRODUCTS',
      payload,
    });
  };
};

export const setActiveNavMenuLevel = (contents, title) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_ACTIVE_CATEGORY',
      payload: { contents, title },
    });
  };
};

const storeMegaMenuData = (device) => {
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  const menuEpoch = localStorage.getItem('megaMenuEpoch');
  const isvMenuInLocalStorage =
    menuEpoch !== null
      ? secondsSinceEpoch - menuEpoch < process.env.NEXT_PUBLIC_VERTICAL_MEGA_MENU_BROWSER_CACHE_TIME_SECONDS
      : false;
  return async (dispatch) => {
    if (!isvMenuInLocalStorage) {
      let megaMenuData = await getContentfulClientEntries({
        content_type: megaMenuContent.type,
        include: 7,
        [megaMenuContent.key]:
          device === 'PAYTM_MINI' || device === 'PHONPE_SWITCH'
            ? process.env.VERTICAL_MENU_SLUG_SHOP_IN_SHOP
            : megaMenuContent.field,
      });
      megaMenuData = wcmsOptimization.megaMenu(megaMenuData);

      localStorage.setItem('megaMenu', JSON.stringify(megaMenuData));

      localStorage.setItem('megaMenuEpoch', secondsSinceEpoch);

      return dispatch({
        type: 'STORE_MEGA_MENU_DATA',
        payload: {
          data: megaMenuData,
        },
      });
    }
    return dispatch({
      type: 'STORE_MEGA_MENU_DATA',
      payload: {
        data: JSON.parse(localStorage.getItem('megaMenu')),
      },
    });
  };
};

const storeNewMegaMenuData = (device) => {
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  const menuEpoch = localStorage.getItem('newMegaMenuEpoch');
  const isvMenuInLocalStorage =
    menuEpoch !== null
      ? secondsSinceEpoch - menuEpoch < process.env.NEXT_PUBLIC_VERTICAL_MEGA_MENU_BROWSER_CACHE_TIME_SECONDS
      : false;
  return async (dispatch) => {
    if (!isvMenuInLocalStorage) {
      let megaMenuData = await getContentfulClientEntries({
        content_type: newMegaMenuContent.type,
        include: 7,
        [newMegaMenuContent.key]:
          device === 'PAYTM_MINI' || device === 'PHONPE_SWITCH'
            ? process.env.VERTICAL_MENU_SLUG_SHOP_IN_SHOP
            : newMegaMenuContent.field,
      });

      megaMenuData = wcmsOptimization.newMegaMenu(megaMenuData);

      localStorage.setItem('newMegaMenu', JSON.stringify(megaMenuData));
      localStorage.setItem('megaMenuEpoch', secondsSinceEpoch);

      dispatch(setActiveNavMenuLevel(megaMenuData, 'Categories'));
      return dispatch({
        type: 'STORE_NEW_MEGA_MENU_DATA',
        payload: {
          data: megaMenuData,
        },
      });
    }

    dispatch(setActiveNavMenuLevel(JSON.parse(localStorage.getItem('newMegaMenu')), 'Categories'));
    return dispatch({
      type: 'STORE_NEW_MEGA_MENU_DATA',
      payload: {
        data: JSON.parse(localStorage.getItem('newMegaMenu')),
      },
    });
  };
};

export const toggleMobileNavigation = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'NAVIGATION_TOGGLE',
      payload,
    });
  };
};

export const setActiveSection = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'ACTIVE_NAV_SECTION',
      payload,
    });
  };
};

const storeSearchBarKeywords = () => {
  const secondsSinceEpoch = Math.round(new Date().getTime() / 1000);
  const expTimeSeachKeywords = localStorage.getItem('seachKeywords_exp_time');
  const storedSBK = localStorage.getItem('searchBarKeywords_data');
  const isSearchKeywordsInLocal =
    expTimeSeachKeywords !== null
      ? secondsSinceEpoch - expTimeSeachKeywords < process.env.NEXT_PUBLIC_VERTICAL_MEGA_MENU_BROWSER_CACHE_TIME_SECONDS
      : false;
  return async (dispatch) => {
    if (!isSearchKeywordsInLocal || !storedSBK) {
      const reponse = await getContentfulClientEntries({
        content_type: appSettingContent.type,
        [appSettingContent.key]: 'searchbar_animation',
        select: appSettingContent.select,
      });
      let { searchBarKeywords = [], defaultSearchText } = JSON.parse(reponse?.items[0]?.fields?.value || '{}');

      searchBarKeywords = wcmsOptimization.searchBarKeys(searchBarKeywords);

      localStorage.setItem('searchBarKeywords_data', JSON.stringify({ searchBarKeywords, defaultSearchText }));
      localStorage.setItem('seachKeywords_exp_time', secondsSinceEpoch);
      return dispatch({
        type: 'STORE_SEARCHBAR_KEYWORDS',
        payload: { searchBarKeywords, defaultSearchText },
      });
    }
    return dispatch({
      type: 'STORE_SEARCHBAR_KEYWORDS',
      payload: {
        ...JSON.parse(localStorage.getItem('searchBarKeywords_data')),
      },
    });
  };
};

export const setCookiesToRedux = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_COOKIES_TO_REDUX',
      payload,
    });
  };
};

export const storeFooterWCMSData = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'STORE_FOOTER_WCMS_DATA',
      payload,
    });
  };
};

const updateAddressTab = () => {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_ADDRESS_TAB',
    });
  };
};

const getPaginateStoreOrders = (range, click) => {
  return (dispatch) => {
    return dispatch({
      type: 'PAGINATE_STORE_ORDERS',
      click,
      payload: range,
    });
  };
};

export const setDevice = ({ payload }) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_DEVICE',
      payload,
    });
  };
};

// action for seeting contextDevice in store
export const setContextDevice = ({ payload }) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_CONTEXT_DEVICE',
      payload,
    });
  };
};

const setAddAddressLoader = ({ payload }) => {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_ADD_ADDRESS_LOADER',
      payload,
    });
  };
};

const setDeliveryOptionsLoader = ({ payload }) => {
  return (dispatch) => {
    return dispatch({
      type: 'ADD_DELIVERY_OPTION',
      payload,
    });
  };
};

const setPaymentTab = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'IS_PAYMENT',
      payload,
    });
  };
};

const setStoreOrdersLoader = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'STORE_ORDERS',
      payload,
    });
  };
};

const setOnlineOrdersLoader = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'DIGITAL_ORDERS',
      payload,
    });
  };
};

const setCancelReasonsLoader = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'CANCEL_REASONS',
      payload,
    });
  };
};

const setDecaSlideModal = (status, modelID) => {
  return (dispatch) => {
    return dispatch({
      type: 'DECA_SLIDE_MODAL_ACTION',
      payload: {
        status,
        modelID,
      },
    });
  };
};

const getPersoRecoData = ({ floorType }) => {
  return async (dispatch) => {
    dispatch({
      type: 'PERSO_RECO_LOADER',
      payload: true,
    });
    const persoRecoResponse = await fetchApi(null, '/api/perso-reco', {
      data: {
        floorType,
      },
      timeout: 10000,
    });

    if (persoRecoResponse?.status && persoRecoResponse?.recommended_products?.length > 0) {
      const modalIds = persoRecoResponse.recommended_products
        .reduce((modalIds, item) => {
          return `${modalIds}${item.num_model},`;
        }, '')
        .replace(/,\s*$/, '');

      const persoRecoWcms = await getContentful({
        type: modelCardContent.type,
        field: [`${modelCardContent.key}[in]`],
        value: modalIds,
      });

      const persoRecoData = [];
      persoRecoResponse.recommended_products?.forEach((element) => {
        persoRecoWcms?.items?.forEach((el) => {
          if (parseInt(element.num_model, 10) === parseInt(el.fields.modelcode, 10)) {
            el.fields.articles = element.articles;
            persoRecoData.push(el);
          }
        });
      });

      dispatch({
        type: 'GET_PERSO_RECO_DATA',
        payload: {
          persoRecoData,
          persoRecoTitle: persoRecoResponse.title,
        },
      });
    } else {
      dispatch({
        type: 'GET_PERSO_RECO_DATA',
        payload: {
          persoRecoData: [],
        },
      });
    }

    dispatch({
      type: 'PERSO_RECO_LOADER',
      payload: false,
    });
    return persoRecoResponse;
  };
};
const setStoreDetail = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'STORE_DETAILS',
      payload,
    });
  };
};

const voiceSearchErrorToggle = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'VOICE_SEARCH_ERROR',
      payload,
    });
  };
};

const setFirstBannerData = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: 'SET_FIRST_CAT_BANNER',
      payload,
    });
  };
};

const getRibbonDisplayMessage = (path) => {
  return async (dispatch) => {
    const pageUrlMaps = {
      '/search': 'Search Page',
      '/shop': 'Sport Page',
      'type=c': 'Search Category Page',
      'type=p': 'Product Page',
      '/': 'Home Page',
    };

    const ribbonBannerData = await getContentfulClientEntries({
      content_type: 'stickyRibbonBanner',
    });

    let payload = ribbonBannerData?.items
      ?.map((data) => {
        return data.fields;
      })
      .filter((data) => {
        if (data.display === 'webAndApp' || data.display === 'webOnly') {
          return data;
        }
      });

    if (payload && payload?.length) {
      const pagePropsKeys = Object.keys(pageUrlMaps);
      const currentPage = pagePropsKeys
        .map((data) => {
          if (path.includes(data)) {
            return pageUrlMaps[data];
          }
        })
        .filter((data) => data !== undefined);
      payload = payload.filter((data) => {
        if (data.displayPage.includes(currentPage[0])) {
          return data;
        }
      });
      if (currentPage.includes('Search Category Page')) {
        dispatch({
          type: 'SET_RIBBON_BANNER_IN_CATEGORY_PAGE',
          payload: true,
        });
      }
    }

    dispatch({
      type: 'SET_RIBBON_BANNER',
      payload,
    });
    return payload;
  };
};

const setRibbonBannerInCategoryPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_RIBBON_BANNER_IN_CATEGORY_PAGE',
      payload,
    });
  };
};
const actions = {
  Toggle,
  toggleVerticalNavigation,
  togglelogin,
  suggestionSearch,
  toggleAddNewAddress,
  storeFooterWCMSData,
  toggledeliveryterms,
  updateAddressTab,
  getPaginateStoreOrders,
  toggleReturnSlide,
  toggleVideoFrame,
  setDevice,
  setContextDevice,
  hideShowTopProducts,
  hideShowSuggestions,
  numberTopProducts,
  storeMegaMenuData,
  storeNewMegaMenuData,
  setAddAddressLoader,
  setDeliveryOptionsLoader,
  setPaymentTab,
  setStoreOrdersLoader,
  setOnlineOrdersLoader,
  setCancelReasonsLoader,
  setDecaSlideModal,
  getPersoRecoData,
  showRecentSearches,
  setStoreDetail,
  storeSearchBarKeywords,
  voiceSearchErrorToggle,
  setFirstBannerData,
  getRibbonDisplayMessage,
  setRibbonBannerInCategoryPage,
};

export default actions;
